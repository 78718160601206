import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../../context/autenticacion/authContext';

// Import styles
import '../../design/styles/pilots-list.scss';
import '../../design/styles/questions.scss';

const ListaPilotos = ({pilotoSeleccionado, SeleccionarPiloto, participantesInscritos, selectedOptionPilot, setSelectedOptionPilot}) => {

    let comodinPiloto = 0;

    const onPilotSelection = value => () => {
        SeleccionarPiloto(value);
        setSelectedOptionPilot('');
        localStorage.setItem('comodinPiloto', (comodinPiloto + 1));
    };

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const [isOpenPilot, setIsOpenPilot] = useState(false);

    const togglingPilot = () => setIsOpenPilot(!isOpenPilot);

    const onOptionClickedPilot = value => () => {
        setSelectedOptionPilot(value);
        setIsOpenPilot(false);
        SeleccionarPiloto(value);
        localStorage.setItem('comodinPiloto', (comodinPiloto));
    };

    const currentParticipante = localStorage.getItem('currentParticipant');

    const fetchDriversData = async () => {
        const API_KEY = "JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP"; // Reemplaza con tu API Key
        const BASE_URL = "https://f1.sportmonks.com/api/v1.0";

        try {
            // 1️⃣ Obtener la lista de IDs de los conductores de la temporada
            const response = await fetch(`${BASE_URL}/drivers/season/10?api_token=${API_KEY}`);
            const data = await response.json();

            if (!data || !data.data) {
                throw new Error("No se pudo obtener la lista de conductores.");
            }

            const driverIds = data.data.map(driver => driver.driver_id); // Extrae los IDs de los conductores

            // 2️⃣ Hacer las peticiones para obtener la información completa de cada conductor
            const driverRequests = driverIds.map(id =>
                fetch(`${BASE_URL}/drivers/${id}?api_token=${API_KEY}`).then(res => res.json())
            );

            // 3️⃣ Esperar a que todas las peticiones terminen
            const driversInfo = await Promise.all(driverRequests);

            // 4️⃣ Extraer los datos y retornarlos
            return driversInfo.map(driver => driver.data);

        } catch (error) {
            console.error("Error al obtener los conductores:", error);
            return [];
        }
    };

    const [apiPilots, setPilots] = useState([])

    useEffect(() => {
        fetchDriversData();
        const loadPilots = async () => {
            const allPilots = await fetchDriversData();
            setPilots(allPilots)
        };
    
        loadPilots();
    }, [])

    return (
        <div className='pilots-list'>


            {apiPilots.map((piloto, index) =>
                <div
                    className='pilot-container'
                    key={index}
                >
                    {participantesInscritos.map(participante => (
                        (currentParticipante === participante.id_participante)
                            ?
                                (
                                    (piloto.name) === participante.pick_piloto_01 ||
                                    (piloto.name) === participante.pick_piloto_02 ||
                                    (piloto.name) === participante.pick_piloto_03 ||
                                    (piloto.name) === participante.pick_piloto_04 ||
                                    (piloto.name) === participante.pick_piloto_05 ||
                                    (piloto.name) === participante.pick_piloto_06 ||
                                    (piloto.name) === participante.pick_piloto_07 ||
                                    (piloto.name) === participante.pick_piloto_08 ||
                                    (piloto.name) === participante.pick_piloto_09 ||
                                    (piloto.name) === participante.pick_piloto_10 ||
                                    (piloto.name) === participante.pick_piloto_11 ||
                                    (piloto.name) === participante.pick_piloto_12 ||
                                    (piloto.name) === participante.pick_piloto_13 ||
                                    (piloto.name) === participante.pick_piloto_14 ||
                                    (piloto.name) === participante.pick_piloto_15 ||
                                    (piloto.name) === participante.pick_piloto_16 ||
                                    (piloto.name) === participante.pick_piloto_17 ||
                                    (piloto.name) === participante.pick_piloto_18 ||
                                    (piloto.name) === participante.pick_piloto_19 ||
                                    (piloto.name) === participante.pick_piloto_20 ||
                                    (piloto.name) === participante.pick_piloto_21 ||
                                    (piloto.name) === participante.pick_piloto_22
                                )   
                                        ?
                                            <div className='already-selected'></div>
                                        :
                                            null
                            :
                                null
                    ))}
                    <div
                        onClick={onPilotSelection(piloto.name)}
                        className={(pilotoSeleccionado === (piloto.name) && selectedOptionPilot === '') ? 'pilot-wrapper selected' : 'pilot-wrapper'}
                    >
                        <div className='info' id={piloto.short_name}>
                            <div className='name'>{piloto.name}</div>
                        </div>
                        <div className='pilot-image'><img src={piloto.image_path} alt='Piloto'/></div>
                    </div>
                </div>
            )}
            {/*}<div className='questions comodins' key={Math.random()}>
                <div className='comodin'>
                    {participantesInscritos.map(participante => (
                        (participante.id_participante === usuario._id) ?
                            (participante.comodines_disponibles_pilotos > 0) ?
                                <div>
                                    <div className='hidden'>{comodinPiloto = participante.comodines_disponibles_pilotos}</div>
                                    {
                                        (participante.comodines_disponibles_pilotos === 1)
                                            ?
                                                <div className='title'>Selecciona tu comodín para pilotos.</div>
                                            :
                                                <div className='title'>Tienes {participante.comodines_disponibles_pilotos} comodines disponibles</div>
                                    }
                                    <div className='custom-select'>
                                        <div className='indication' onClick={togglingPilot}>
                                            <span className='text'>{selectedOptionPilot || "Elige tu respuesta"}</span>
                                        </div>
                                        {isOpenPilot && (
                                            <div className='options-wrapper'>
                                                <ul className='list-opts'>
                                                    {apiPilots.map(piloto => (
                                                        <li
                                                            onClick={
                                                                onOptionClickedPilot(piloto.name + '*')
                                                            }
                                                            key={Math.random()}
                                                        >
                                                            {piloto.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            :
                            <div className='title'>Ya no tienes comodines disponibles</div>
                        : null
                    ))}
                    </div>
                </div>{*/}
            </div>
    );
}

export default ListaPilotos;