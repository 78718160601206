import React, { useState, useEffect, useContext } from 'react';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import AlertaContext from '../../context/alertas/alertaContext';
import MainNavigation from '../partials/MainNavigation';
import { Link } from 'react-router-dom';
import '../../design/styles/strategy-results.scss';

const Estrategia = () => {

    //extraer los valores del context
    const alertaContext = useContext(AlertaContext);
    const {alerta, mostrarAlerta} = alertaContext;

    let index = localStorage.getItem('index');
    let currentRace = JSON.parse(localStorage.getItem('currentRace'));

    const [stage, setStage] = useState(null);
    const [sprint, setSprint] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStage = async () => {
            const API_TOKEN = "JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP";
            const url = `https://f1.sportmonks.com/api/v1.0/stages/${currentRace.race_id}?api_token=${API_TOKEN}`;

            try {
                const response = await fetch(url);
                if (!response.ok) throw new Error("No se pudo obtener la información");

                const data = await response.json();
                setStage(data.data); // Ajusta según la estructura de respuesta
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchStage();
    }, [currentRace.race_id]);

    useEffect(() => {
        if(currentRace.sprint_id) {
            const fetchStage = async () => {
                const API_TOKEN = "JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP";
                const url = `https://f1.sportmonks.com/api/v1.0/stages/${currentRace.sprint_id}?api_token=${API_TOKEN}`;

                try {
                    const response = await fetch(url);
                    if (!response.ok) throw new Error("No se pudo obtener la información");

                    const data = await response.json();
                    setSprint(data.data); // Ajusta según la estructura de respuesta
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchStage();
        } else {
            console.log('No hay sprint')
        }
    }, [currentRace.sprint_id]);

    const puntosRace = {
        p1: 25,
        p2: 18,
        p3: 15,
        p4: 12,
        p5: 10,
        p6: 8,
        p7: 6,
        p8: 4,
        p9: 2,
        p10: 1,
        p11: 0,
        p12: 0,
        p13: 0,
        p14: 0,
        p15: 0,
        p16: 0,
        p17: 0,
        p18: 0,
        p19: 0,
        p20: 0
    };
    
    const puntosSprint = {
        p1: 8,
        p2: 7,
        p3: 6,
        p4: 5,
        p5: 4,
        p6: 3,
        p7: 2,
        p8: 1,
        p9: 0,
        p10: 0,
        p11: 0,
        p12: 0,
        p13: 0,
        p14: 0,
        p15: 0,
        p16: 0,
        p17: 0,
        p18: 0,
        p19: 0,
        p20: 0
    };

    const [puntos, guardarPuntos] = useState({
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: '',
        p8: '',
        p9: '',
        p10: '',
        p11: '',
        p12: '',
        p13: '',
        p14: '',
        p15: '',
        p16: '',
        p17: '',
        p18: '',
        p19: '',
        p20: ''
    });

    // Función para calcular los puntos totales
    const calcularPuntos = (raceResult, sprintResult) => {
        let totalPuntos = {};

        // Procesar carrera principal
        raceResult.forEach((piloto, index) => {
            let posicion = `p${index + 1}`;
            let puntos = puntosRace[posicion] || 0;

            if (!totalPuntos[piloto]) {
                totalPuntos[piloto] = 0;
            }

            totalPuntos[piloto] += puntos;
        });

        // Procesar sprint (si hay)
        if (sprintResult) {
            sprintResult.forEach((piloto, index) => {
                let posicion = `p${index + 1}`;
                let puntos = puntosSprint[posicion] || 0;

                if (!totalPuntos[piloto]) {
                    totalPuntos[piloto] = 0;
                }

                totalPuntos[piloto] += puntos;
            });
        }

        // Crear objeto de estado con los puntos en el mismo orden
        let nuevoEstadoPuntos = {};
        raceResult.forEach((piloto, index) => {
            let posicion = `p${index + 1}`;
            nuevoEstadoPuntos[posicion] = totalPuntos[piloto] || 0;
        });

        guardarPuntos(nuevoEstadoPuntos);
    };

    // Ejecutar la función y mostrar el resultado
    useEffect(() => {
        if(stage && stage.results.data[0]) {
    
            // Resultados de carrera y sprint
            const raceResult = [
                stage.results.data[0].driver.data.name,
                stage.results.data[1].driver.data.name,
                stage.results.data[2].driver.data.name,
                stage.results.data[3].driver.data.name,
                stage.results.data[4].driver.data.name,
                stage.results.data[5].driver.data.name,
                stage.results.data[6].driver.data.name,
                stage.results.data[7].driver.data.name,
                stage.results.data[8].driver.data.name,
                stage.results.data[9].driver.data.name,
                stage.results.data[10].driver.data.name,
                stage.results.data[11].driver.data.name,
                stage.results.data[12].driver.data.name,
                stage.results.data[13].driver.data.name,
                stage.results.data[14].driver.data.name,
                stage.results.data[15].driver.data.name,
                stage.results.data[16].driver.data.name,
                stage.results.data[17].driver.data.name,
                stage.results.data[18].driver.data.name,
                stage.results.data[19].driver.data.name
            ];

            let sprintResult = []

            if(sprint) {
                sprintResult = [
                    sprint.results.data[0].driver.data.name,
                    sprint.results.data[1].driver.data.name,
                    sprint.results.data[2].driver.data.name,
                    sprint.results.data[3].driver.data.name,
                    sprint.results.data[4].driver.data.name,
                    sprint.results.data[5].driver.data.name,
                    sprint.results.data[6].driver.data.name,
                    sprint.results.data[7].driver.data.name,
                    sprint.results.data[8].driver.data.name,
                    sprint.results.data[9].driver.data.name,
                    sprint.results.data[10].driver.data.name,
                    sprint.results.data[11].driver.data.name,
                    sprint.results.data[12].driver.data.name,
                    sprint.results.data[13].driver.data.name,
                    sprint.results.data[14].driver.data.name,
                    sprint.results.data[15].driver.data.name,
                    sprint.results.data[16].driver.data.name,
                    sprint.results.data[17].driver.data.name,
                    sprint.results.data[18].driver.data.name,
                    sprint.results.data[19].driver.data.name
                ];
            }

            calcularPuntos(raceResult, sprintResult);
        } else {
            console.log('Aun no hay resultados')
        }
    }, [stage])

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {actualizarParticipante} = listaContext;
    const {participantesInscritosWarm, obtenerParticipantesWarm} = listaContext;
    const {participantesInscritosWomen, obtenerParticipantesWomen} = listaContext;

    const {p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19, p20} = puntos;

    const [selectedOptionPilotOne, setSelectedOptionPilotOne] = useState(null);

    const [selectedOptionPilotTwo, setSelectedOptionPilotTwo] = useState(null);

    const [selectedOptionPilotThree, setSelectedOptionPilotThree] = useState(null);

    const [selectedOptionPilotFour, setSelectedOptionPilotFour] = useState(null);

    const [selectedOptionPilotFive, setSelectedOptionPilotFive] = useState(null);

    const [selectedOptionPilotSix, setSelectedOptionPilotSix] = useState(null);

    const [selectedOptionPilotSeven, setSelectedOptionPilotSeven] = useState(null);

    const [selectedOptionPilotEight, setSelectedOptionPilotEight] = useState(null);

    const [selectedOptionPilotNine, setSelectedOptionPilotNine] = useState(null);

    const [selectedOptionPilotTen, setSelectedOptionPilotTen] = useState(null);

    const [selectedOptionPilotEleven, setSelectedOptionPilotEleven] = useState(null);

    const [selectedOptionPilotTwelve, setSelectedOptionPilotTwelve] = useState(null);

    const [selectedOptionPilotThirteen, setSelectedOptionPilotThirteen] = useState(null);

    const [selectedOptionPilotForteen, setSelectedOptionPilotForteen] = useState(null);

    const [selectedOptionPilotFifteen, setSelectedOptionPilotFifteen] = useState(null);

    const [selectedOptionPilotSixteen, setSelectedOptionPilotSixteen] = useState(null);

    const [selectedOptionPilotSeventeen, setSelectedOptionPilotSeventeen] = useState(null);

    const [selectedOptionPilotEighteen, setSelectedOptionPilotEighteen] = useState(null);

    const [selectedOptionPilotNineteen, setSelectedOptionPilotNineteen] = useState(null);

    const [selectedOptionPilotTwenty, setSelectedOptionPilotTwenty] = useState(null);

    useEffect(() => {
        if(stage && stage.results.data[0]) {
            if(stage?.results) {
                setSelectedOptionPilotOne(stage.results.data[0].driver.data.name+'*')
                setSelectedOptionPilotTwo(stage.results.data[1].driver.data.name+'*')
                setSelectedOptionPilotThree(stage.results.data[2].driver.data.name+'*')
                setSelectedOptionPilotFour(stage.results.data[3].driver.data.name+'*')
                setSelectedOptionPilotFive(stage.results.data[4].driver.data.name+'*')
                setSelectedOptionPilotSix(stage.results.data[5].driver.data.name+'*')
                setSelectedOptionPilotSeven(stage.results.data[6].driver.data.name+'*')
                setSelectedOptionPilotEight(stage.results.data[7].driver.data.name+'*')
                setSelectedOptionPilotNine(stage.results.data[8].driver.data.name+'*')
                setSelectedOptionPilotTen(stage.results.data[9].driver.data.name+'*')
                setSelectedOptionPilotEleven(stage.results.data[10].driver.data.name+'*')
                setSelectedOptionPilotTwelve(stage.results.data[11].driver.data.name+'*')
                setSelectedOptionPilotThirteen(stage.results.data[12].driver.data.name+'*')
                setSelectedOptionPilotForteen(stage.results.data[13].driver.data.name+'*')
                setSelectedOptionPilotFifteen(stage.results.data[14].driver.data.name+'*')
                setSelectedOptionPilotSixteen(stage.results.data[15].driver.data.name+'*')
                setSelectedOptionPilotSeventeen(stage.results.data[16].driver.data.name+'*')
                setSelectedOptionPilotEighteen(stage.results.data[17].driver.data.name+'*')
                setSelectedOptionPilotNineteen(stage.results.data[18].driver.data.name+'*')
                setSelectedOptionPilotTwenty(stage.results.data[19].driver.data.name+'*')
            }
        }
    }, [stage])

    useEffect(() => {
        obtenerParticipantesWarm('65c2abef784e25b32d5224ae');
        obtenerParticipantesWomen('65c2abca784e25b32d5224ac');
    }, [])

    const onChange = (e) => {
        guardarPuntos({
            ...puntos,
            [e.target.name] : [e.target.value]
        });
    }
    
    const saveInfo = () => {
        let puntosMcLaren = 0,
            puntosMercedes = 0,
            puntosRedBull = 0,
            puntosWilliams = 0,
            puntosAstonMartin = 0,
            puntosKickSauber = 0,
            puntosFerrari = 0,
            puntosAlpine = 0,
            puntosRacingBulls = 0,
            puntosHaas = 0;

        if (selectedOptionPilotOne === null || p1 === '' ||
            selectedOptionPilotTwo === null || p2 === '' ||
            selectedOptionPilotThree === null || p3 === '' ||
            selectedOptionPilotFour === null || p4 === '' ||
            selectedOptionPilotFive === null || p5 === '' ||
            selectedOptionPilotSix === null || p6 === '' ||
            selectedOptionPilotSeven === null || p7 === '' ||
            selectedOptionPilotEight === null || p8 === '' ||
            selectedOptionPilotNine === null || p9 === '' ||
            selectedOptionPilotTen === null || p10 === '' ||
            selectedOptionPilotEleven === null || p11 === '' ||
            selectedOptionPilotTwelve === null || p12 === '' ||
            selectedOptionPilotThirteen === null || p13 === '' ||
            selectedOptionPilotForteen === null || p14 === '' ||
            selectedOptionPilotFifteen === null || p15 === '' ||
            selectedOptionPilotSixteen === null || p16 === '' ||
            selectedOptionPilotSeventeen === null || p17 === '' ||
            selectedOptionPilotEighteen === null || p18 === '' ||
            selectedOptionPilotNineteen === null || p19 === '' ||
            selectedOptionPilotTwenty === null || p20 === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
            return;
        }

        const arrayValidation = [
            selectedOptionPilotOne,
            selectedOptionPilotTwo,
            selectedOptionPilotThree,
            selectedOptionPilotFour,
            selectedOptionPilotFive,
            selectedOptionPilotSix,
            selectedOptionPilotSeven,
            selectedOptionPilotEight,
            selectedOptionPilotNine,
            selectedOptionPilotTen,
            selectedOptionPilotEleven,
            selectedOptionPilotTwelve,
            selectedOptionPilotThirteen,
            selectedOptionPilotForteen,
            selectedOptionPilotFifteen,
            selectedOptionPilotSixteen,
            selectedOptionPilotSeventeen,
            selectedOptionPilotEighteen,
            selectedOptionPilotNineteen,
            selectedOptionPilotTwenty,
        ]

        let repetido = false;
        for (let i = 0; i < arrayValidation.length; i++) {
            for (let j = 0; j < arrayValidation.length; j++) {
                if (arrayValidation[i] == arrayValidation[j] && i != j) { //revisamos que i sea diferente de j, para que no compare el mismo elemento exacto.
                    repetido= true;
                }
            }
        }

        if(repetido === true) {
            mostrarAlerta('Hay un piloto repetido.', 'alerta-error')
            return
        }

        mostrarAlerta('Datos correctos', 'alerta-ok');

        if (selectedOptionPilotOne === 'Lando Norris (GBR)*' || selectedOptionPilotOne === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lando Norris (GBR)*' || selectedOptionPilotTwo === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lando Norris (GBR)*' || selectedOptionPilotThree === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lando Norris (GBR)*' || selectedOptionPilotFour === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lando Norris (GBR)*' || selectedOptionPilotFive === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lando Norris (GBR)*' || selectedOptionPilotSix === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lando Norris (GBR)*' || selectedOptionPilotSeven === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lando Norris (GBR)*' || selectedOptionPilotEight === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lando Norris (GBR)*' || selectedOptionPilotNine === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lando Norris (GBR)*' || selectedOptionPilotTen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lando Norris (GBR)*' || selectedOptionPilotEleven === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lando Norris (GBR)*' || selectedOptionPilotTwelve === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lando Norris (GBR)*' || selectedOptionPilotThirteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lando Norris (GBR)*' || selectedOptionPilotForteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lando Norris (GBR)*' || selectedOptionPilotFifteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lando Norris (GBR)*' || selectedOptionPilotSixteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lando Norris (GBR)*' || selectedOptionPilotSeventeen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lando Norris (GBR)*' || selectedOptionPilotEighteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lando Norris (GBR)*' || selectedOptionPilotNineteen === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lando Norris (GBR)*' || selectedOptionPilotTwenty === 'Oscar Piastri (AUS)*') {
            puntosMcLaren = puntosMcLaren + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Kimi Antonelli (ITA)*' || selectedOptionPilotOne === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Kimi Antonelli (ITA)*' || selectedOptionPilotTwo === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Kimi Antonelli (ITA)*' || selectedOptionPilotThree === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Kimi Antonelli (ITA)*' || selectedOptionPilotFour === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Kimi Antonelli (ITA)*' || selectedOptionPilotFive === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Kimi Antonelli (ITA)*' || selectedOptionPilotSix === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Kimi Antonelli (ITA)*' || selectedOptionPilotSeven === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Kimi Antonelli (ITA)*' || selectedOptionPilotEight === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Kimi Antonelli (ITA)*' || selectedOptionPilotNine === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotTen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Kimi Antonelli (ITA)*' || selectedOptionPilotEleven === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Kimi Antonelli (ITA)*' || selectedOptionPilotTwelve === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotThirteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotForteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotFifteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotSixteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotSeventeen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotEighteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Kimi Antonelli (ITA)*' || selectedOptionPilotNineteen === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Kimi Antonelli (ITA)*' || selectedOptionPilotTwenty === 'George Russell (GBR)*') {
            puntosMercedes = puntosMercedes + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Max Verstappen (NED)*' || selectedOptionPilotOne === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Max Verstappen (NED)*' || selectedOptionPilotTwo === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Max Verstappen (NED)*' || selectedOptionPilotThree === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Max Verstappen (NED)*' || selectedOptionPilotFour === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Max Verstappen (NED)*' || selectedOptionPilotFive === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Max Verstappen (NED)*' || selectedOptionPilotSix === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Max Verstappen (NED)*' || selectedOptionPilotSeven === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Max Verstappen (NED)*' || selectedOptionPilotEight === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Max Verstappen (NED)*' || selectedOptionPilotNine === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Max Verstappen (NED)*' || selectedOptionPilotTen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Max Verstappen (NED)*' || selectedOptionPilotEleven === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Max Verstappen (NED)*' || selectedOptionPilotTwelve === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Max Verstappen (NED)*' || selectedOptionPilotThirteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Max Verstappen (NED)*' || selectedOptionPilotForteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Max Verstappen (NED)*' || selectedOptionPilotFifteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Max Verstappen (NED)*' || selectedOptionPilotSixteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Max Verstappen (NED)*' || selectedOptionPilotSeventeen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Max Verstappen (NED)*' || selectedOptionPilotEighteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Max Verstappen (NED)*' || selectedOptionPilotNineteen === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Max Verstappen (NED)*' || selectedOptionPilotTwenty === 'Liam Lawson (NZL)*') {
            puntosRedBull = puntosRedBull + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Alexander Albon (THA)*' || selectedOptionPilotOne === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Alexander Albon (THA)*' || selectedOptionPilotTwo === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Alexander Albon (THA)*' || selectedOptionPilotThree === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Alexander Albon (THA)*' || selectedOptionPilotFour === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Alexander Albon (THA)*' || selectedOptionPilotFive === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Alexander Albon (THA)*' || selectedOptionPilotSix === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Alexander Albon (THA)*' || selectedOptionPilotSeven === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Alexander Albon (THA)*' || selectedOptionPilotEight === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Alexander Albon (THA)*' || selectedOptionPilotNine === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Alexander Albon (THA)*' || selectedOptionPilotTen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Alexander Albon (THA)*' || selectedOptionPilotEleven === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Alexander Albon (THA)*' || selectedOptionPilotTwelve === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Alexander Albon (THA)*' || selectedOptionPilotThirteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Alexander Albon (THA)*' || selectedOptionPilotForteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Alexander Albon (THA)*' || selectedOptionPilotFifteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Alexander Albon (THA)*' || selectedOptionPilotSixteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Alexander Albon (THA)*' || selectedOptionPilotSeventeen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Alexander Albon (THA)*' || selectedOptionPilotEighteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Alexander Albon (THA)*' || selectedOptionPilotNineteen === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Alexander Albon (THA)*' || selectedOptionPilotTwenty === 'Carlos Sainz Jr (ESP)*') {
            puntosWilliams = puntosWilliams + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Lance Stroll (CAN)*' || selectedOptionPilotOne === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lance Stroll (CAN)*' || selectedOptionPilotTwo === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lance Stroll (CAN)*' || selectedOptionPilotThree === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lance Stroll (CAN)*' || selectedOptionPilotFour === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lance Stroll (CAN)*' || selectedOptionPilotFive === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lance Stroll (CAN)*' || selectedOptionPilotSix === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lance Stroll (CAN)*' || selectedOptionPilotSeven === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lance Stroll (CAN)*' || selectedOptionPilotEight === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lance Stroll (CAN)*' || selectedOptionPilotNine === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lance Stroll (CAN)*' || selectedOptionPilotTen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lance Stroll (CAN)*' || selectedOptionPilotEleven === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lance Stroll (CAN)*' || selectedOptionPilotTwelve === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lance Stroll (CAN)*' || selectedOptionPilotThirteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lance Stroll (CAN)*' || selectedOptionPilotForteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lance Stroll (CAN)*' || selectedOptionPilotFifteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lance Stroll (CAN)*' || selectedOptionPilotSixteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lance Stroll (CAN)*' || selectedOptionPilotSeventeen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lance Stroll (CAN)*' || selectedOptionPilotEighteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lance Stroll (CAN)*' || selectedOptionPilotNineteen === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lance Stroll (CAN)*' || selectedOptionPilotTwenty === 'Fernando Alonso (ESP)*') {
            puntosAstonMartin = puntosAstonMartin + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Nico Hulkenberg (GER)*' || selectedOptionPilotOne === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Nico Hulkenberg (GER)*' || selectedOptionPilotTwo === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Nico Hulkenberg (GER)*' || selectedOptionPilotThree === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Nico Hulkenberg (GER)*' || selectedOptionPilotFour === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Nico Hulkenberg (GER)*' || selectedOptionPilotFive === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Nico Hulkenberg (GER)*' || selectedOptionPilotSix === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Nico Hulkenberg (GER)*' || selectedOptionPilotSeven === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Nico Hulkenberg (GER)*' || selectedOptionPilotEight === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Nico Hulkenberg (GER)*' || selectedOptionPilotNine === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotTen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Nico Hulkenberg (GER)*' || selectedOptionPilotEleven === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Nico Hulkenberg (GER)*' || selectedOptionPilotTwelve === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotThirteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotForteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotFifteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotSixteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotSeventeen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotEighteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Nico Hulkenberg (GER)*' || selectedOptionPilotNineteen === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Nico Hulkenberg (GER)*' || selectedOptionPilotTwenty === 'Gabriel Bortoleto (BRA)*') {
            puntosKickSauber = puntosKickSauber + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Lewis Hamilton (GBR)*' || selectedOptionPilotOne === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Lewis Hamilton (GBR)*' || selectedOptionPilotTwo === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Lewis Hamilton (GBR)*' || selectedOptionPilotThree === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Lewis Hamilton (GBR)*' || selectedOptionPilotFour === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Lewis Hamilton (GBR)*' || selectedOptionPilotFive === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Lewis Hamilton (GBR)*' || selectedOptionPilotSix === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Lewis Hamilton (GBR)*' || selectedOptionPilotSeven === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Lewis Hamilton (GBR)*' || selectedOptionPilotEight === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Lewis Hamilton (GBR)*' || selectedOptionPilotNine === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotTen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Lewis Hamilton (GBR)*' || selectedOptionPilotEleven === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Lewis Hamilton (GBR)*' || selectedOptionPilotTwelve === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotThirteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotForteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotFifteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotSixteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotSeventeen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotEighteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Lewis Hamilton (GBR)*' || selectedOptionPilotNineteen === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Lewis Hamilton (GBR)*' || selectedOptionPilotTwenty === 'Charles Leclerc (MON)*') {
            puntosFerrari = puntosFerrari + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Jack Doohan (AUS)*' || selectedOptionPilotOne === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Jack Doohan (AUS)*' || selectedOptionPilotTwo === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Jack Doohan (AUS)*' || selectedOptionPilotThree === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Jack Doohan (AUS)*' || selectedOptionPilotFour === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Jack Doohan (AUS)*' || selectedOptionPilotFive === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Jack Doohan (AUS)*' || selectedOptionPilotSix === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Jack Doohan (AUS)*' || selectedOptionPilotSeven === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Jack Doohan (AUS)*' || selectedOptionPilotEight === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Jack Doohan (AUS)*' || selectedOptionPilotNine === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Jack Doohan (AUS)*' || selectedOptionPilotTen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Jack Doohan (AUS)*' || selectedOptionPilotEleven === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Jack Doohan (AUS)*' || selectedOptionPilotTwelve === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Jack Doohan (AUS)*' || selectedOptionPilotThirteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Jack Doohan (AUS)*' || selectedOptionPilotForteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Jack Doohan (AUS)*' || selectedOptionPilotFifteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Jack Doohan (AUS)*' || selectedOptionPilotSixteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Jack Doohan (AUS)*' || selectedOptionPilotSeventeen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Jack Doohan (AUS)*' || selectedOptionPilotEighteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Jack Doohan (AUS)*' || selectedOptionPilotNineteen === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Jack Doohan (AUS)*' || selectedOptionPilotTwenty === 'Pierre Gasly (FRA)*') {
            puntosAlpine = puntosAlpine + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Isack Hadjar (FRA)*' || selectedOptionPilotOne === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Isack Hadjar (FRA)*' || selectedOptionPilotTwo === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Isack Hadjar (FRA)*' || selectedOptionPilotThree === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Isack Hadjar (FRA)*' || selectedOptionPilotFour === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Isack Hadjar (FRA)*' || selectedOptionPilotFive === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Isack Hadjar (FRA)*' || selectedOptionPilotSix === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Isack Hadjar (FRA)*' || selectedOptionPilotSeven === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Isack Hadjar (FRA)*' || selectedOptionPilotEight === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Isack Hadjar (FRA)*' || selectedOptionPilotNine === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Isack Hadjar (FRA)*' || selectedOptionPilotTen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Isack Hadjar (FRA)*' || selectedOptionPilotEleven === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Isack Hadjar (FRA)*' || selectedOptionPilotTwelve === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotThirteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotForteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotFifteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotSixteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Isack Hadjar (FRA)*' || selectedOptionPilotSeventeen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotEighteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Isack Hadjar (FRA)*' || selectedOptionPilotNineteen === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Isack Hadjar (FRA)*' || selectedOptionPilotTwenty === 'Yuki Tsunoda (JPN)*') {
            puntosRacingBulls = puntosRacingBulls + parseInt(p20);
        }
        if (selectedOptionPilotOne === 'Esteban Ocon (FRA)*' || selectedOptionPilotOne === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p1);
        }
        if (selectedOptionPilotTwo === 'Esteban Ocon (FRA)*' || selectedOptionPilotTwo === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p2);
        }
        if (selectedOptionPilotThree === 'Esteban Ocon (FRA)*' || selectedOptionPilotThree === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p3);
        }
        if (selectedOptionPilotFour === 'Esteban Ocon (FRA)*' || selectedOptionPilotFour === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p4);
        }
        if (selectedOptionPilotFive === 'Esteban Ocon (FRA)*' || selectedOptionPilotFive === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p5);
        }
        if (selectedOptionPilotSix === 'Esteban Ocon (FRA)*' || selectedOptionPilotSix === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p6);
        }
        if (selectedOptionPilotSeven === 'Esteban Ocon (FRA)*' || selectedOptionPilotSeven === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p7);
        }
        if (selectedOptionPilotEight === 'Esteban Ocon (FRA)*' || selectedOptionPilotEight === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p8);
        }
        if (selectedOptionPilotNine === 'Esteban Ocon (FRA)*' || selectedOptionPilotNine === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p9);
        }
        if (selectedOptionPilotTen === 'Esteban Ocon (FRA)*' || selectedOptionPilotTen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p10);
        }
        if (selectedOptionPilotEleven === 'Esteban Ocon (FRA)*' || selectedOptionPilotEleven === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p11);
        }
        if (selectedOptionPilotTwelve === 'Esteban Ocon (FRA)*' || selectedOptionPilotTwelve === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p12);
        }
        if (selectedOptionPilotThirteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotThirteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p13);
        }
        if (selectedOptionPilotForteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotForteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p14);
        }
        if (selectedOptionPilotFifteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotFifteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p15);
        }
        if (selectedOptionPilotSixteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotSixteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p16);
        }
        if (selectedOptionPilotSeventeen === 'Esteban Ocon (FRA)*' || selectedOptionPilotSeventeen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p17);
        }
        if (selectedOptionPilotEighteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotEighteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p18);
        }
        if (selectedOptionPilotNineteen === 'Esteban Ocon (FRA)*' || selectedOptionPilotNineteen === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p19);
        }
        if (selectedOptionPilotTwenty === 'Esteban Ocon (FRA)*' || selectedOptionPilotTwenty === 'Oliver Bearman (GBR)*') {
            puntosHaas = puntosHaas + parseInt(p20);
        }

        participantesInscritosWomen.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_03 || selectedOptionPilotOne === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_03 || selectedOptionPilotTwo === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_03 || selectedOptionPilotThree === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_03 || selectedOptionPilotFour === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_03 || selectedOptionPilotFive === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_03 || selectedOptionPilotSix === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_03 || selectedOptionPilotSeven === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_03 || selectedOptionPilotEight === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_03 || selectedOptionPilotNine === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_03 || selectedOptionPilotTen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_03 || selectedOptionPilotEleven === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_03 || selectedOptionPilotTwelve === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_03 || selectedOptionPilotThirteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_03 || selectedOptionPilotForteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_03 || selectedOptionPilotFifteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_03 || selectedOptionPilotSixteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_03 || selectedOptionPilotSeventeen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_03 || selectedOptionPilotEighteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_03 || selectedOptionPilotNineteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_03 || selectedOptionPilotTwenty === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }

            if (selectedOptionPilotOne === participante.pick_posicion1_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_03: 0
                });
            }

            if (selectedOptionPilotTwo === participante.pick_posicion2_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_03: 0
                });
            }

            if (selectedOptionPilotThree === participante.pick_posicion3_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_03: 0
                });
            }

            if (selectedOptionPilotFour === participante.pick_posicion4_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_03: 0
                });
            }

            if (selectedOptionPilotFive === participante.pick_posicion5_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_03: 0
                });
            }

            if (participante.pick_constructor_03 === 'Red Bull' || participante.pick_constructor_03 === 'Red Bull*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.pick_constructor_03 === 'Mercedes' || participante.pick_constructor_03 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.pick_constructor_03 === 'Ferrari' || participante.pick_constructor_03 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.pick_constructor_03 === 'McLaren' || participante.pick_constructor_03 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.pick_constructor_03 === 'Alpine' || participante.pick_constructor_03 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.pick_constructor_03 === 'Racing Bulls' || participante.pick_constructor_03 === 'Racing Bulls*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosRacingBulls
                });
                puntosTotales = puntosTotales + puntosRacingBulls;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRacingBulls;
            }

            if (participante.pick_constructor_03 === 'Aston Martin' || participante.pick_constructor_03 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.pick_constructor_03 === 'Williams' || participante.pick_constructor_03 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.pick_constructor_03 === 'Kick Sauber' || participante.pick_constructor_03 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosKickSauber
                });
                puntosTotales = puntosTotales + puntosKickSauber;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosKickSauber;
            }

            if (participante.pick_constructor_03 === 'Haas' || participante.pick_constructor_03 === 'Haas*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.pick_constructor_03 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: 0
                });
            }

            if (participante.pick_piloto_03 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })

        participantesInscritosWarm.map(participante => {

            let puntosTotales,
                puntosTotalesPiloto,
                puntosTotalesConstructor;

            if (!participante.puntos) {
                puntosTotales = 0;
            } else {
                puntosTotales = parseFloat(participante.puntos);
            }

            if (!participante.puntos_pick_piloto) {
                puntosTotalesPiloto = 0;
            } else {
                puntosTotalesPiloto = parseFloat(participante.puntos_pick_piloto);
            }

            if (!participante.puntos_pick_constructor) {
                puntosTotalesConstructor = 0;
            } else {
                puntosTotalesConstructor = parseFloat(participante.puntos_pick_constructor);
            }

            if (selectedOptionPilotOne === participante.pick_piloto_03 || selectedOptionPilotOne === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p1)
                });
                puntosTotales = puntosTotales + parseInt(p1);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p1);
            }
            if (selectedOptionPilotTwo === participante.pick_piloto_03 || selectedOptionPilotTwo === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p2)
                });
                puntosTotales = puntosTotales + parseInt(p2);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p2);
            }
            if (selectedOptionPilotThree === participante.pick_piloto_03 || selectedOptionPilotThree === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p3)
                });
                puntosTotales = puntosTotales + parseInt(p3);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p3);
            }
            if (selectedOptionPilotFour === participante.pick_piloto_03 || selectedOptionPilotFour === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p4)
                });
                puntosTotales = puntosTotales + parseInt(p4);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p4);
            }
            if (selectedOptionPilotFive === participante.pick_piloto_03 || selectedOptionPilotFive === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p5)
                });
                puntosTotales = puntosTotales + parseInt(p5);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p5);
            }
            if (selectedOptionPilotSix === participante.pick_piloto_03 || selectedOptionPilotSix === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p6)
                });
                puntosTotales = puntosTotales + parseInt(p6);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p6);
            }
            if (selectedOptionPilotSeven === participante.pick_piloto_03 || selectedOptionPilotSeven === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p7)
                });
                puntosTotales = puntosTotales + parseInt(p7);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p7);
            }
            if (selectedOptionPilotEight === participante.pick_piloto_03 || selectedOptionPilotEight === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p8)
                });
                puntosTotales = puntosTotales + parseInt(p8);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p8);
            }
            if (selectedOptionPilotNine === participante.pick_piloto_03 || selectedOptionPilotNine === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p9)
                });
                puntosTotales = puntosTotales + parseInt(p9);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p9);
            }
            if (selectedOptionPilotTen === participante.pick_piloto_03 || selectedOptionPilotTen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p10)
                });
                puntosTotales = puntosTotales + parseInt(p10);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p10);
            }
            if (selectedOptionPilotEleven === participante.pick_piloto_03 || selectedOptionPilotEleven === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p11)
                });
                puntosTotales = puntosTotales + parseInt(p11);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p11);
            }
            if (selectedOptionPilotTwelve === participante.pick_piloto_03 || selectedOptionPilotTwelve === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p12)
                });
                puntosTotales = puntosTotales + parseInt(p12);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p12);
            }
            if (selectedOptionPilotThirteen === participante.pick_piloto_03 || selectedOptionPilotThirteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p13)
                });
                puntosTotales = puntosTotales + parseInt(p13);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p13);
            }
            if (selectedOptionPilotForteen === participante.pick_piloto_03 || selectedOptionPilotForteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p14)
                });
                puntosTotales = puntosTotales + parseInt(p14);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p14);
            }
            if (selectedOptionPilotFifteen === participante.pick_piloto_03 || selectedOptionPilotFifteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p15)
                });
                puntosTotales = puntosTotales + parseInt(p15);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p15);
            }
            if (selectedOptionPilotSixteen === participante.pick_piloto_03 || selectedOptionPilotSixteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p16)
                });
                puntosTotales = puntosTotales + parseInt(p16);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p16);
            }
            if (selectedOptionPilotSeventeen === participante.pick_piloto_03 || selectedOptionPilotSeventeen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p17)
                });
                puntosTotales = puntosTotales + parseInt(p17);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p17);
            }
            if (selectedOptionPilotEighteen === participante.pick_piloto_03 || selectedOptionPilotEighteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p18)
                });
                puntosTotales = puntosTotales + parseInt(p18);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p18);
            }
            if (selectedOptionPilotNineteen === participante.pick_piloto_03 || selectedOptionPilotNineteen === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p19)
                });
                puntosTotales = puntosTotales + parseInt(p19);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p19);
            }
            if (selectedOptionPilotTwenty === participante.pick_piloto_03 || selectedOptionPilotTwenty === participante.pick_piloto_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: parseInt(p20)
                });
                puntosTotales = puntosTotales + parseInt(p20);
                puntosTotalesPiloto = puntosTotalesPiloto + parseInt(p20);
            }

            if (selectedOptionPilotOne === participante.pick_posicion1_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion1_03: 0
                });
            }

            if (selectedOptionPilotTwo === participante.pick_posicion2_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion2_03: 0
                });
            }

            if (selectedOptionPilotThree === participante.pick_posicion3_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion3_03: 0
                });
            }

            if (selectedOptionPilotFour === participante.pick_posicion4_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion4_03: 0
                });
            }

            if (selectedOptionPilotFive === participante.pick_posicion5_03 + '*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_03: 3
                });
                puntosTotales = puntosTotales + 3;
            } else {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_posicion5_03: 0
                });
            }

            if (participante.pick_constructor_03 === 'Red Bull' || participante.pick_constructor_03 === 'Red Bull*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosRedBull
                });
                puntosTotales = puntosTotales + puntosRedBull;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRedBull;
            }

            if (participante.pick_constructor_03 === 'Mercedes' || participante.pick_constructor_03 === 'Mercedes*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosMercedes
                });
                puntosTotales = puntosTotales + puntosMercedes;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMercedes;
            }

            if (participante.pick_constructor_03 === 'Ferrari' || participante.pick_constructor_03 === 'Ferrari*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosFerrari
                });
                puntosTotales = puntosTotales + puntosFerrari;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosFerrari;
            }

            if (participante.pick_constructor_03 === 'McLaren' || participante.pick_constructor_03 === 'McLaren*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosMcLaren
                });
                puntosTotales = puntosTotales + puntosMcLaren;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosMcLaren;
            }

            if (participante.pick_constructor_03 === 'Alpine' || participante.pick_constructor_03 === 'Alpine*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosAlpine
                });
                puntosTotales = puntosTotales + puntosAlpine;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAlpine;
            }

            if (participante.pick_constructor_03 === 'Racing Bulls' || participante.pick_constructor_03 === 'Racing Bulls*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosRacingBulls
                });
                puntosTotales = puntosTotales + puntosRacingBulls;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosRacingBulls;
            }

            if (participante.pick_constructor_03 === 'Aston Martin' || participante.pick_constructor_03 === 'Aston Martin*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosAstonMartin
                });
                puntosTotales = puntosTotales + puntosAstonMartin;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosAstonMartin;
            }

            if (participante.pick_constructor_03 === 'Williams' || participante.pick_constructor_03 === 'Williams*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosWilliams
                });
                puntosTotales = puntosTotales + puntosWilliams;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosWilliams;
            }

            if (participante.pick_constructor_03 === 'Kick Sauber' || participante.pick_constructor_03 === 'Kick Sauber*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosKickSauber
                });
                puntosTotales = puntosTotales + puntosKickSauber;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosKickSauber;
            }

            if (participante.pick_constructor_03 === 'Haas' || participante.pick_constructor_03 === 'Haas*') {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: puntosHaas
                });
                puntosTotales = puntosTotales + puntosHaas;
                puntosTotalesConstructor = puntosTotalesConstructor + puntosHaas;
            }

            if (participante.pick_constructor_03 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_constructor_03: 0
                });
            }

            if (participante.pick_piloto_03 === undefined) {
                actualizarParticipante({
                    _id: participante._id,
                    puntos_pick_piloto_03: 0
                });
            }
            actualizarParticipante({
                _id: participante._id,
                puntos: puntosTotales,
                puntos_pick_piloto: puntosTotalesPiloto,
                puntos_pick_constructor: puntosTotalesConstructor
            });
        })
    }

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className='outer-wrapper'>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <MainNavigation/>
            <section className='block strategy-results'>
                <h1>{index} {currentRace.city} - {currentRace.name}</h1>
                <h2>Resultados de la carrera</h2>
                <div className='table'>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='table-header'>POS. GEN.</div>
                        </div>
                        <div className='pilot-field'>
                            <div className='table-header'>PILOTO</div>
                        </div>
                        <div className='points-field'>
                            <div className='table-header'>PUNTOS</div>
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P1</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotOne || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                name='p1'
                                min='0'
                                type="number"
                                onChange={onChange}
                                value={p1}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P2</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotTwo || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p2'
                                type="number"
                                onChange={onChange}
                                value={p2}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P3</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotThree || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p3'
                                type="number"
                                onChange={onChange}
                                value={p3}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P4</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotFour || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p4'
                                type="number"
                                onChange={onChange}
                                value={p4}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P5</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotFive || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p5'
                                type="number"
                                onChange={onChange}
                                value={p5}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P6</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotSix || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p6'
                                type="number"
                                onChange={onChange}
                                value={p6}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P7</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotSeven || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p7'
                                type="number"
                                onChange={onChange}
                                value={p7}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P8</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotEight || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p8'
                                type="number"
                                onChange={onChange}
                                value={p8}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P9</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotNine || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p9'
                                type="number"
                                onChange={onChange}
                                value={p9}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P10</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotTen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p10'
                                type="number"
                                onChange={onChange}
                                value={p10}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P11</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotEleven || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p11'
                                type="number"
                                onChange={onChange}
                                value={p11}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P12</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotTwelve || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p12'
                                type="number"
                                onChange={onChange}
                                value={p12}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P13</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotThirteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p13'
                                type="number"
                                onChange={onChange}
                                value={p13}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P14</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotForteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p14'
                                type="number"
                                onChange={onChange}
                                value={p14}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P15</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotFifteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p15'
                                type="number"
                                onChange={onChange}
                                value={p15}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P16</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotSixteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p16'
                                type="number"
                                onChange={onChange}
                                required
                                value={p16}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P17</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotSeventeen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p17'
                                type="number"
                                onChange={onChange}
                                value={p17}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P18</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotEighteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p18'
                                type="number"
                                onChange={onChange}
                                value={p18}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P19</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotNineteen || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p19'
                                type="number"
                                onChange={onChange}
                                value={p19}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'>P20</div>
                        </div>
                        <div className='pilot field'>
                            <div className='custom-select'>
                                <div className='indication'>
                                    <span className='text'>{selectedOptionPilotTwenty || "Esperando resultados..."}</span>
                                </div>
                            </div>
                        </div>
                        <div className='points-field'>
                            <input
                                min='0'
                                name='p20'
                                type="number"
                                onChange={onChange}
                                value={p20}
                            />
                        </div>
                    </div>
                    <div className='flex-content'>
                        <div className='position-field'>
                            <div className='position'></div>
                        </div>
                        <div className='pilot-field'>Puntos totales:</div>
                        <div className='points-field'>
                            {
                                (parseInt(p1) >= 0 && parseInt(p2) >= 0 && parseInt(p3) >= 0 && parseInt(p4) >= 0 && parseInt(p5) >= 0 && parseInt(p6) >= 0 && parseInt(p7) >= 0 && parseInt(p8) >= 0 && parseInt(p9) >= 0 && parseInt(p10) >= 0 && parseInt(p11) >= 0 && parseInt(p12) >= 0 && parseInt(p13) >= 0 && parseInt(p14) >= 0 && parseInt(p15) >= 0 && parseInt(p16) >= 0 && parseInt(p17) >= 0 && parseInt(p18) >= 0 && parseInt(p19) >= 0 && parseInt(p20) >= 0)
                                    ?
                                        parseInt(p1) + parseInt(p2) + parseInt(p3) + parseInt(p4) + parseInt(p5) + parseInt(p6) + parseInt(p7) + parseInt(p8) + parseInt(p9) + parseInt(p10) + parseInt(p11) + parseInt(p12) + parseInt(p13) + parseInt(p14) + parseInt(p15) + parseInt(p16) + parseInt(p17) + parseInt(p18) + parseInt(p19) + parseInt(p20)
                                    :
                                        null
                            } pts
                        </div>
                    </div>
                </div>
                <div
                    to='/resultados-carrera'
                    className='save-btn'
                    onClick={saveInfo}
                >
                    Guardar
                </div>
                <Link to={"/administrador"} className="back">
                    <div className="text">Regresar</div>
                </Link>
            </section>
        </div>
    )
}

export default Estrategia;