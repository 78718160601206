import React, {useContext, useEffect} from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import concursoContext from '../../context/concursos/concursoContext';
import AlertaContext from '../../context/alertas/alertaContext';
import DatosCompetencia from './DatosCompetencia';
import ListaParticipantes from './ListaParticipantes';

// Import styles
import '../../design/styles/competition.scss';
import '../../design/styles/competitors-list.scss';
import concurso01 from '../../design/imgs/women-elite.webp';
import concurso03 from '../../design/imgs/warmup.webp';
import concurso05 from '../../design/imgs/goat.webp';

const Competition = () => {

    // Extraer concursos de state inicial
    const concursosContext = useContext(concursoContext);
    const {listaParticipantes, mensaje, concursos, concurso, obtenerConcursos} = concursosContext;

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    // Obtener concursos cuando carga el componente
    useEffect(() => {

        // Si hay un error
        if (mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }

        obtenerConcursos();
        // eslint-disable-next-line
    }, [mensaje]);
    
    // Revisar si hay concursos
    if(concursos.length === 0) return null;
    
    let concursoInState;

    if (!concurso) {
        concursoInState = 0;
    } else {
        concursoInState = concurso[0]._id
    }

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
        slides: '.item',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: "unslick"
            }
        ]
    };

    return (
        <section className='block competitions'>
            <img className='hidden' src={concurso01} alt='Imagen Concurso'/>
            <img className='hidden' src={concurso03} alt='Imagen Concurso'/>
            <img className='hidden' src={concurso05} alt='Imagen Concurso'/>
            {alerta ? (<div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>) : null}
            <div className='holder'>
                <div className='content'>
                    <Slider {...settings} className='carrousel'>
                        {concursos.map(currentConcurso => (
                            !listaParticipantes && concursoInState !== currentConcurso._id
                                ?
                                    (    
                                        <DatosCompetencia
                                            key={currentConcurso._id}
                                            currentConcurso={currentConcurso}
                                        />
                                    )
                                :
                                (    
                                    <ListaParticipantes
                                        key={currentConcurso._id}
                                        currentConcurso={currentConcurso}
                                    />
                                )
                        ))}
                    </Slider>
                    <Link
                        to={'/creditos'}
                        className="credits-btn"
                    >
                        Solicitar créditos
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Competition;