import React, {Fragment, useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import concursoContext from '../../context/concursos/concursoContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';
import Modal from '@material-ui/core/Modal';
//import ModalNickname from '../modal/modalNickname';
import styled from 'styled-components';
import '../../design/styles/modal.scss';
import AuthContext from '../../context/autenticacion/authContext';

import firstPlace from '../../design/imgs/pop-up.jpg';
import close from '../../design/imgs/close.svg';
import closeHover from '../../design/imgs/close2.svg';

const Background = styled.div`
    background-image: url(${firstPlace});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const BackgroundElement = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:after {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 0%, #000000 100%);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

/*function getModalStyle() {
    const top = 50 ;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}*/

const ListaParticipantes = ({currentConcurso}) => {

    // Configuración del modal de material-ui
    //const[modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    // Obtener el state del formulario
    const concursosContext = useContext(concursoContext);
    const {concursoActual} = concursosContext;

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {participantesInscritos, agregarParticipante} = listaContext;

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario, actualizarUsuario} = authContext;

    let creditosRequeridos = currentConcurso.creditos,
        creditosTotales = usuario.creditos;

    //State para guardar Nickname
    const [nickName, guardarNickname] = useState({
        nickname: ''
    });

    // State para cambiar componente
    const [nickComplete, cambiarEstado] = useState(false);

    // Extrar de usuario
    const {nickname} = nickName;

    const onChange = e => {
        guardarNickname({
            ...nickName,
            [e.target.name] : [e.target.value]
        });
    };

    const onSubmit = e => {
        e.preventDefault();
        localStorage.setItem('nickname', nickname);
        cambiarEstado(true);
    }

    const onRegister = e => {
        e.preventDefault();

        //agregar el nuevo participante al state de concursos
        agregarParticipante({
            nickname: localStorage.getItem('nickname'),
            id_concurso: currentConcurso._id,
            id_participante: usuario._id,
            puntos: 0,
            puntos_pick_constructor: 0,
            puntos_pick_piloto: 0
        });
        actualizarUsuario({
            id: usuario._id,
            creditos: parseInt(creditosTotales) - parseInt(creditosRequeridos)
        });
        handleClose();
        localStorage.removeItem('nickname');
        cambiarEstado(false);
        guardarNickname({
            ...nickName,
            nickname : ''
        });
    }
    
    let classButton = true,
        inscrito = false,
        carrerasTerminadas = 0;

    // Si no hay concurso seleccionado
    if (!concursoActual) return <h2>Hubo un error :(</h2>;
    
    return (
        <div className='item'>
            <div className='item-wrapper'>
                <BackgroundElement
                    className="background"
                    style={{
                        backgroundImage: `url(${currentConcurso.image_src})`
                    }}
                />
                <div className='list-holder competitors-holder'>
                    <div className='header'>
                        <div className='title'>F1 QUINIELA 2025</div>
                        <ul className='competitors-list'>
                            <li>
                                <div className='col-left'><b>Usuarios</b></div>
                                <div className='col-right'><b>Puntos</b></div>
                            </li>
                            {participantesInscritos.map((participant) => {
                                if(participant.id_participante === usuario._id) {
                                    classButton = false;
                                    inscrito = true;
                                    if (participant.carreras_terminadas !== null) {
                                        carrerasTerminadas = participant.carreras_terminadas;
                                    } else {
                                        carrerasTerminadas = '0';
                                    }
                                }
                            })}
                            {participantesInscritos.length === 0
                                ?
                                    (
                                        <li
                                            onClick={() => {
                                                handleOpen()
                                            }}
                                        >
                                            <button className='register'>Inscribirme</button>
                                        </li>
                                    )
                                :
                                    <Fragment>
                                        <li className='list-scroll'>
                                            <ul className='competitors-list main'>
                                        {participantesInscritos.map((participant, index) => (
                                            <Fragment key={index}>
                                            {participant
                                                ?
                                                <li className={(participant.id_participante === usuario._id) ? 'active': null}>
                                                    <div className='col-left'>
                                                        <div className='table-position'>{index + 1}.</div>
                                                        <div className='name'>{participant.nickname}</div>
                                                    </div>
                                                    <div className='col-right'>
                                                        <div className='points'>{participant.puntos}</div>
                                                    </div>
                                                </li>
                                                : null
                                            }
                                            </Fragment>
                                        ))}
                                        </ul>
                                        </li>
                                        <li
                                            onClick={() => {
                                                handleOpen()
                                            }}
                                        >
                                            <button className='register'>Inscribirme</button>
                                        </li>
                                        {
                                            inscrito === true
                                                ?
                                                    <Link
                                                        className='li'
                                                        to={'/carreras'}
                                                        onClick={() => {
                                                            localStorage.setItem('currentConcurso', JSON.stringify(currentConcurso));
                                                            localStorage.setItem('carrerasTerminadas', carrerasTerminadas);
                                                            localStorage.setItem('currentParticipant', usuario._id);
                                                        }}
                                                    >
                                                        <button className='register'>Jugar</button>
                                                    </Link>
                                                :
                                                        null
                                        }
                                    </Fragment>
                            }
                            <Modal
                                open={open}
                                onClose={() => {
                                    handleClose()
                                }}
                            >
                            <div className='pl-modal'>
                                <div
                                    className='close-modal'
                                    onClick={() => {
                                        handleClose();
                                        localStorage.removeItem('nickname');
                                        cambiarEstado(false);
                                        guardarNickname({
                                            ...nickName,
                                            nickname : ''
                                        });
                                    }}
                                >
                                    <img className='icon-normal' src={close} alt='Botón Cerrar Modal'/>
                                    <img className='icon-hover' src={closeHover} alt='Botón Cerrar Modal'/>
                                </div>
                                <Background/>
                                <div className='holder'>
                                    <div className='container-fluid'>
                                        <div className='header'>
                                            <div className='title'>F1 QUINIELA 2025</div>
                                            <div className='desc'>{currentConcurso.nombre}</div>
                                        </div>
                                        <div className='content'>
                                            {!nickComplete
                                                ?
                                                <Fragment>
                                                    <div className='indications'>¿Con qué Nickname desas jugar?</div>
                                                    <form
                                                        onSubmit={onSubmit}
                                                    >
                                                        <input
                                                            id='nickname-to-play'
                                                            type='text'
                                                            name='nickname'
                                                            value={nickname}
                                                            onChange={onChange}
                                                            required='required'
                                                        />
                                                        <label>Escribe tu nickname</label>
                                                        <input type='submit' value='Jugar'/>
                                                    </form>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <div className='welcome-message'>¡Hola, {localStorage.getItem('nickname')}!</div>
                                                    {parseInt(creditosTotales) < parseInt(creditosRequeridos)
                                                        ?
                                                            <Fragment>
                                                                <div className='negative-message'>No tienes suficientes créditos para jugar esta quiniela, por favor contacta al adimnistrador.</div>
                                                                <Link to={'/creditos'} className='gplk-btn'>Solicitar créditos</Link>
                                                                <span
                                                                    className='cancel'
                                                                    onClick={() => {
                                                                        handleClose();
                                                                        localStorage.removeItem('nickname');
                                                                        cambiarEstado(false);
                                                                        guardarNickname({
                                                                            ...nickName,
                                                                            nickname : ''
                                                                        });
                                                                }}
                                                                >Cancelar</span>
                                                            </Fragment>
                                                        :
                                                            <Fragment>
                                                                <div className='advertising'>TE VA A TOMAR <span>{creditosRequeridos} CRÉDITOS</span> JUGAR ESTA QUINIELA</div>
                                                                <button
                                                                    className='confirm'
                                                                    onClick={onRegister}
                                                                >Continuar</button>
                                                                <span
                                                                    className='cancel'
                                                                    onClick={() => {
                                                                        handleClose();
                                                                        localStorage.removeItem('nickname');
                                                                        cambiarEstado(false);
                                                                        guardarNickname({
                                                                            ...nickName,
                                                                            nickname : ''
                                                                        });
                                                                }}
                                                                >Cancelar</span>
                                                            </Fragment>
                                                    }
                                                </Fragment>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Modal>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListaParticipantes;