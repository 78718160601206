import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AuthContext from '../../context/autenticacion/authContext';
import concursoContext from '../../context/concursos/concursoContext';
import listaParticipantesContext from '../../context/listaParticipantes/listaParticipantesContext';

import firstPlace from '../../design/imgs/gold.svg';
import secondPlace from '../../design/imgs/silver.svg';
import thirdPlace from '../../design/imgs/bronce.svg';
import fourthPlace from '../../design/imgs/medalla.svg';

const Background = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:after {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.20) 0%, #000000 100%);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

const DatosCompetencia = ({currentConcurso}) => {

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    // Obtener el state del formulario
    const concursosContext = useContext(concursoContext);
    const {concursoActual} = concursosContext;

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {obtenerParticipantes} = listaContext;

    const seleccionarConcurso = id => {
        concursoActual(id);
        obtenerParticipantes(id);
    }

    return (
        
        <div className='item'>
            <div className='item-wrapper'>
                <Background
                    className='background'
                    style={{
                        backgroundImage: `url(${currentConcurso.image_src})`
                    }}
                />
                <div className='item-holder'>
                    <div className='header'>
                        <div className='title'>F1 QUINIELA 2025</div>
                        <div className='desc'>{currentConcurso.nombre}</div>
                        <div className='credits'>{currentConcurso.creditos}CR</div>
                    </div>
                    <div className="item-content">
                        <div className="cupo">
                            <span>Participantes inscritos:</span>
                            <span>No hay límite</span>
                        </div>
                        {/*}<div className="bolsa">
                            <span>Bolsa en juego:</span>
                            <span>{currentConcurso.bolsa}{(currentConcurso.cupo === 'Ilimitado') ? null : 'CR' }</span>
                        </div>{*/}
                    </div>
                    <div className="prizes-content">
                        <div className="prizes-title">Premios para:</div>
                        <ul>
                            <li>
                                <span className="position">1º lugar</span>
                                <span className="percentage">{currentConcurso.premio1}%</span>
                                <span className="icon">
                                    <img src={firstPlace} alt="Primer lugar" />
                                </span>
                            </li>
                            <li>
                                <span className="position">2º lugar</span>
                                <span className="percentage">{currentConcurso.premio2}%</span>
                                <span className="icon">
                                    <img src={secondPlace} alt="Segundo lugar" />
                                </span>
                            </li>
                            <li>
                                <span className="position">3º lugar</span>
                                <span className="percentage">{currentConcurso.premio3}%</span>
                                <span className="icon">
                                    <img src={thirdPlace} alt="Tercer lugar" />
                                </span>
                            </li>
                            <li>
                                <span className="position">10º lugar</span>
                                <span className="percentage">{currentConcurso.premio4}</span>
                                <span className="icon">
                                    <img src={fourthPlace} alt="Extra" />
                                </span>
                            </li>
                        </ul>
                        <button
                            className="gplk-btn"
                            onClick={() => seleccionarConcurso(currentConcurso._id)}
                        >Entrar</button>
                        {
                            (usuario)
                                ?
                                    (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                        ?
                                            <Link
                                                className='gplk-btn'
                                                to={'/carreras'}
                                                onClick={() => {
                                                    localStorage.setItem('currentConcurso', JSON.stringify(currentConcurso));
                                                    localStorage.setItem('currentParticipant', usuario._id);
                                                }}
                                            >
                                                Ver concurso
                                            </Link>
                                        :
                                            null
                                :
                                    null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DatosCompetencia;