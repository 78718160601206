import React from 'react';
import { Link } from 'react-router-dom';

// Import Styles
import '../../design/styles/footer-register.scss';

const FooterRegister = () => {

    return (
        <footer className="footer-register">
            <div className="content">
                <ul className="opts">
                    <li>
                        <span>©2025 Todos los derechos reservados</span>
                    </li>
                    <li>
                        <a href="/files/aviso-de-privacidad.pdf" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
                    </li>
                    <li>
                        <a href="/files/terminos-y-condiciones.pdf" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                    </li>
                    <li>
                        <a href="/files/datos-personales.pdf" target="_blank" rel="noopener noreferrer">Protección de Datos</a>
                    </li>
                    <Link to={'/contacto'}>
                        <span>Contáctanos</span>
                    </Link>
                </ul>
            </div>
        </footer>
    );
}

export default FooterRegister;