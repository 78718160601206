import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import listaParticipantesContext from "../../context/listaParticipantes/listaParticipantesContext";
import AuthContext from '../../context/autenticacion/authContext';

import styled from "styled-components";
import "../../design/styles/modal-races.scss";
import backIcon from "../../design/imgs/arrow-l-1.svg";

import qatar from "../../design/imgs/flag-01.png";
import arabia from "../../design/imgs/flag-02.png";
import australia from "../../design/imgs/flag-03.png";
import china from "../../design/imgs/china.svg";
import italia from "../../design/imgs/flag-04.png";
import usa from "../../design/imgs/flag-05.png";
import espana from "../../design/imgs/flag-06.png";
import monaco from "../../design/imgs/flag-07.png";
import azerbaijan from "../../design/imgs/flag-08.png";
import canada from "../../design/imgs/flag-09.png";
import bretana from "../../design/imgs/flag-10.png";
import austria from "../../design/imgs/flag-11.png";
import hungria from "../../design/imgs/flag-13.png";
import belgica from "../../design/imgs/flag-14.png";
import holanda from "../../design/imgs/flag-15.png";
import singapur from "../../design/imgs/flag-17.png";
import japon from "../../design/imgs/flag-18.png";
import mexico from "../../design/imgs/flag-20.png";
import brasil from "../../design/imgs/flag-21.png";
import emirates from "../../design/imgs/flag-22.png";

import modalBackground from "../../design/imgs/pop-up.jpg";
import close from "../../design/imgs/close.svg";
import closeHover from "../../design/imgs/close2.svg";

const Background = styled.div`
    background-image: url(${modalBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const ListaCarreras = ({concurso, carreras}) => {

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    // Obtener las tareas del proyecto
    const listaContext = useContext(listaParticipantesContext);
    const {obtenerParticipantes} = listaContext;

    const cargaListaUsuarios = id => {
        obtenerParticipantes(id);
    }

    const convertToCDMXTime = (utcTimestamp) => {
        return new Intl.DateTimeFormat("es-MX", {
            timeZone: "America/Mexico_City",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).format(new Date(utcTimestamp * 1000)); // Convertimos a milisegundos
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split(',')[0].split('/');
        const date = new Date(`${year}-${month}-${day}T00:00:00`); // Mantiene la fecha en local

        return `${day} ${date.toLocaleString('en-US', { month: 'short' }).toUpperCase()}`;
    };

    const extractTime = (dateString) => {
        return dateString.split(', ')[1].slice(0, 5); // Extrae solo HH:MM
    };

    const getDayOfWeek = (dateString) => {
        const [day, month, year] = dateString.split(',')[0].split('/');
        const date = new Date(`${year}-${month}-${day}T00:00:00`);

        return date.toLocaleString('es-ES', { weekday: 'short' }).toUpperCase();
    };

    const formatDateWithMinusOneMinute = (dateString) => {
        // Extraer partes de la fecha
        const [day, month, year] = dateString.split(', ')[0].split('/');
        const time = dateString.split(', ')[1];

        // Crear el objeto Date en formato correcto (YYYY-MM-DDTHH:mm:ss)
        let date = new Date(`${year}-${month}-${day}T${time}`);

        // Restar 1 minuto
        date.setMinutes(date.getMinutes() - 1);

        // Formatear el día de la semana en español
        const dayOfWeek = date.toLocaleString('es-ES', { weekday: 'long' });

        // Obtener la hora en formato HH:MM
        const formattedTime = date.toTimeString().slice(0, 5); // "HH:MM"

        return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)} ${formattedTime}`;
    };
    
    const formatDateTime = (date) => {
        const day = String(date.getDate()).padStart(2, '0'); // Día con 2 dígitos
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con 2 dígitos
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0'); // Horas con 2 dígitos
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minutos con 2 dígitos
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Segundos con 2 dígitos

        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    };

    const checkDate = (dateString) => {
        // Obtener la fecha actual
        const now = new Date();

        // Extraer partes de la fecha objetivo (DD/MM/YYYY HH:mm:ss)
        const [day, month, year] = dateString.split(', ')[0].split('/');
        const time = dateString.split(', ')[1];

        // Convertir a un objeto Date en formato correcto
        const target = new Date(`${year}-${month}-${day}T${time}`);

        // Calcular la diferencia en días
        const diffInMs = target - now;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24); // Convertir ms a días

        // Activar el botón si la diferencia es 4 días o menos
        return diffInDays <= 4;
    };

    const checkDeadline = (dateString) => {
        // Obtener la fecha actual
        const now = new Date();

        // Extraer partes de la fecha objetivo (DD/MM/YYYY HH:mm:ss)
        const [day, month, year] = dateString.split(", ")[0].split("/");
        const time = dateString.split(", ")[1];

        // Crear el objeto Date en formato correcto (YYYY-MM-DDTHH:mm:ss)
        const target = new Date(`${year}-${month}-${day}T${time}`);

        // Verificar si la fecha actual es igual o mayor a la establecida
        return now < target;
    };

    //const[modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        localStorage.removeItem("currentRace");
        localStorage.removeItem("index");
    }

    let currentRace = "",
        index = "",
        carrerasTerminadas = localStorage.getItem("carrerasTerminadas");
    if (localStorage.getItem("currentRace")) {
        currentRace = JSON.parse(localStorage.getItem("currentRace"));
        index = localStorage.getItem("index");
    }

    const fetchStages = async () => {
        try {
            const response = await fetch(
                `https://f1.sportmonks.com/api/v1.0/stages/season/10?api_token=JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP`
            );
            const data = await response.json();

            // Filtrar las etapas
            const relevantStages = data.data.filter(
                (stage) => stage.name === "Race"
                            || stage.name === "First Qualification"
                            || stage.name === "Sprint Race"
                            || stage.name === "First Practice"
                            || stage.name === "Second Practice"
                            || stage.name === "Third Practice"
                            || stage.name === "Sprint Shootout 1"
            );

            // Retornar un array con { track_id, start_date }
            return relevantStages.map((stage) => ({
                track_id: stage.track_id,
                name: stage.name,
                start_date: convertToCDMXTime(stage.time.starting_at.timestamp),
                total_laps: stage.total_laps,
                distance: stage.distance,
                city: stage.city
            }));

        } catch (error) {
            console.error("Error fetching stages:", error);
            return [];
        }
    };

    const fetchTrackDetails = async (trackIds) => {
        try {
            const response = await fetch(
                `https://f1.sportmonks.com/api/v1.0/tracks?api_token=JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP`
            );
            const data = await response.json();

            // Filtrar solo las pistas que están en trackIds
            return data.data.filter((track) => trackIds.includes(track.id));
        } catch (error) {
            console.error("Error fetching tracks:", error);
            return [];
        }
    };

    // Función para convertir "DD/MM/YYYY, HH:mm:ss" → "YYYY-MM-DDTHH:mm:ss"
    const parseDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(", ");
        const [day, month, year] = datePart.split("/");

        return new Date(`${year}-${month}-${day}T${timePart}`);
    };

    const getTracks = async () => {
        // 1. Obtener las etapas (Race, First Qualification y First Race)
        const stages = await fetchStages();

        // 2. Extraer los track_id únicos
        const trackIds = [...new Set(stages.map((stage) => stage.track_id))];

        // 3. Obtener los detalles de las pistas
        const trackDetails = await fetchTrackDetails(trackIds);

        // 4. Combinar las pistas con sus eventos relevantes
        const mergedData = trackDetails.map((track) => {
            const race = stages.find((s) => s.track_id === track.id && s.name === "Race");
            const qualification = stages.find((s) => s.track_id === track.id && s.name === "First Qualification");
            const firstRace = stages.find((s) =>s.track_id === track.id && s.name === "Sprint Race");
            const firstPractice = stages.find((s) =>s.track_id === track.id && s.name === "First Practice");
            const secondPractice = stages.find((s) =>s.track_id === track.id && s.name === "Second Practice");
            const thirdPractice = stages.find((s) =>s.track_id === track.id && s.name === "Third Practice");
            const sprintQuali = stages.find((s) =>s.track_id === track.id && s.name === "Sprint Shootout 1");

            return {
                ...track,
                race_date: race ? race.start_date : null,
                total_laps: race ? race.total_laps : null,
                city: race ? race.city : null,
                distance: race ? race.distance : null,
                qualification_date: qualification ? qualification.start_date : null,
                first_race_date: firstRace ? firstRace.start_date : null,
                first_practice_date: firstPractice ? firstPractice.start_date : null,
                second_practice_date: secondPractice ? secondPractice.start_date : null,
                third_practice_date: thirdPractice ? thirdPractice.start_date : null,
                sprint_quali_date: sprintQuali ? sprintQuali.start_date : null
            };
        });

        // 5. Ordenar por fecha de inicio
        const sortedTracks = mergedData
            .filter((track) => track.race_date) // Filtrar los que tienen fecha
            .sort((a, b) => parseDate(a.race_date) - parseDate(b.race_date));

        return sortedTracks;
    };

    const [tracks, setTracks] = useState([]);

    useEffect(() => {
        const loadTracks = async () => {
            const sortedTracks = await getTracks();
            setTracks(sortedTracks);
        };
    
        loadTracks();
    }, []);

    return (
        <section className="block races-list">

            <div className="holder">
                <div className="container-fluid">
                    <div className="content">
                        {
                            tracks.map((track, index) => (
                                <div className="race" key={track.id}>
                                    <div className="race-date" id={"race-" + (index + 1)}>
                                        <div className="flex-content">
                                            <div className="left-side">
                                                <div className="index">{(index < 9) ? "0" + (index + 1) : (index + 1)}</div>
                                            </div>
                                            <div className="flag">
                                                {
                                                    (track.city == 'Melbourne')
                                                        ?
                                                            <img src={australia} alt="Bandera" />
                                                        :
                                                            (track.city == 'Shanghai')
                                                                ?
                                                                    <img src={china} alt="Bandera" />
                                                                :
                                                                    (track.city == 'Suzuka')
                                                                        ?
                                                                            <img src={japon} alt="Bandera" />
                                                                        :
                                                                            (track.city == 'Sakhir' || track.city == 'Losail')
                                                                                ?
                                                                                    <img src={qatar} alt="Bandera" />
                                                                                :
                                                                                    (track.city == 'Miami' || track.city == 'Las Vegas' || track.city == 'Austin')
                                                                                        ?
                                                                                            <img src={usa} alt="Bandera" />
                                                                                        :
                                                                                            (track.city == 'Imola' || track.city == 'Monza')
                                                                                                ?
                                                                                                    <img src={italia} alt="Bandera" />
                                                                                                :
                                                                                                    (track.city == 'Jeddah')
                                                                                                        ?
                                                                                                            <img src={arabia} alt="Bandera" />
                                                                                                        :
                                                                                                            (track.city == 'Monte Carlo')
                                                                                                                ?
                                                                                                                    <img src={monaco} alt="Bandera" />
                                                                                                                :
                                                                                                                    (track.city == 'Barcelona')
                                                                                                                        ?
                                                                                                                            <img src={espana} alt="Bandera" />
                                                                                                                        :
                                                                                                                            (track.city == 'Montreal')
                                                                                                                                ?
                                                                                                                                    <img src={canada} alt="Bandera" />
                                                                                                                                :
                                                                                                                                    (track.city == 'Spielberg')
                                                                                                                                        ?
                                                                                                                                            <img src={austria} alt="Bandera" />
                                                                                                                                        :
                                                                                                                                            (track.city == 'Silverstone')
                                                                                                                                                ?
                                                                                                                                                    <img src={bretana} alt="Bandera" />
                                                                                                                                                :
                                                                                                                                                    (track.city == 'Spa')
                                                                                                                                                        ?
                                                                                                                                                            <img src={belgica} alt="Bandera" />
                                                                                                                                                        :
                                                                                                                                                            (track.city == 'Mogyord')
                                                                                                                                                                ?
                                                                                                                                                                    <img src={hungria} alt="Bandera" />
                                                                                                                                                                :
                                                                                                                                                                    (track.city == 'Zandvoort')
                                                                                                                                                                        ?
                                                                                                                                                                            <img src={holanda} alt="Bandera" />
                                                                                                                                                                        :
                                                                                                                                                                            (track.city == 'Baku')
                                                                                                                                                                                ?
                                                                                                                                                                                    <img src={azerbaijan} alt="Bandera" />
                                                                                                                                                                                :
                                                                                                                                                                                    (track.city == 'Singapore')
                                                                                                                                                                                        ?
                                                                                                                                                                                            <img src={singapur} alt="Bandera" />
                                                                                                                                                                                        :
                                                                                                                                                                                            (track.city == 'Mexico City')
                                                                                                                                                                                                ?
                                                                                                                                                                                                    <img src={mexico} alt="Bandera" />
                                                                                                                                                                                                :
                                                                                                                                                                                                    (track.city == 'Interlagos')
                                                                                                                                                                                                        ?
                                                                                                                                                                                                            <img src={brasil} alt="Bandera" />
                                                                                                                                                                                                        :
                                                                                                                                                                                                            (track.city == 'Abu Dhabi')
                                                                                                                                                                                                                ?
                                                                                                                                                                                                                    <img src={emirates} alt="Bandera" />
                                                                                                                                                                                                                :
                                                                                                                                                                                                                    null
                                                }
                                            </div>
                                            <div className="right-side">
                                                <div className="country">{track.city}</div>
                                                <div className="city">{track.name}</div>
                                                <div className="date">{formatDate(track.first_practice_date)} - {formatDate(track.race_date)}</div>
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                handleOpen();
                                                localStorage.setItem("currentRace", JSON.stringify(track));
                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                            }}
                                            className="schedule"
                                            id={(checkDate(track.first_practice_date)) ? "active" : null }
                                        >Horarios</div>
                                        {
                                            (track.first_race_date)
                                                ?
                                                    (checkDeadline(track.first_practice_date))
                                                        ?
                                                            <Link
                                                                to={"estrategia-carrera"}
                                                                className="strategy-mob visible-xs"
                                                                id={(checkDate(track.first_practice_date)) ? "active" : null }
                                                                onClick={() => {
                                                                    handleOpen();
                                                                    localStorage.setItem("currentRace", JSON.stringify(track));
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }}
                                                            >Estrategia</Link>
                                                        :
                                                            <Link
                                                                to={"resultados-carrera"}
                                                                className="race-results-mob visible-xs"
                                                                onClick={
                                                                    () => {
                                                                        cargaListaUsuarios(concurso._id);
                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                    }
                                                                }
                                                            >Ver resultados</Link>
                                                :
                                                    (checkDeadline(track.qualification_date))
                                                        ?
                                                            <Link
                                                                to={"estrategia-carrera"}
                                                                className="strategy-mob visible-xs"
                                                                id={(checkDate(track.first_practice_date)) ? "active" : null }
                                                                onClick={() => {
                                                                    handleOpen();
                                                                    localStorage.setItem("currentRace", JSON.stringify(track));
                                                                    localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                }}
                                                            >Estrategia</Link>
                                                        :
                                                            <Link
                                                                to={"resultados-carrera"}
                                                                className="race-results-mob visible-xs"
                                                                onClick={
                                                                    () => {
                                                                        cargaListaUsuarios(concurso._id);
                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                    }
                                                                }
                                                            >Ver resultados</Link>
                                        }
                                        {
                                            (usuario.email === 'david7_qh@hotmail.com' || usuario.email === 'jdecolombres_11@hotmail.com' || usuario.email === 'alvarordlp@gmail.com')
                                                ?
                                                    <Link
                                                        to={"resultados-carrera"}
                                                        className="race-results"
                                                        onClick={
                                                            () => {
                                                                cargaListaUsuarios(concurso._id);
                                                                localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                localStorage.setItem("currentRace", JSON.stringify(track));
                                                            }
                                                        }
                                                    >Ver tablero</Link>
                                                :
                                                    null
                                        }
                                    </div>
                                    <div className="race-info hidden-xs">
                                        <div className="race-data">
                                            <div className="flex-content">
                                                <div className="ml">
                                                    <span>Número de vueltas</span>
                                                    <b>{track.total_laps}</b>
                                                </div>
                                            </div>
                                            <div className="flex-content">
                                                <div className="ml">
                                                    <span>Longitud del circuito</span>
                                                    <b>{track.length}<i>km</i></b>
                                                </div>
                                            </div>
                                            <div className='flex-content'>
                                                <div className="ml">
                                                    <span>Distancia de carrera</span>
                                                    <b>{track.distance}<i>km</i></b>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="race-map">
                                                <div className="map">
                                                    {
                                                        (track.image_path)
                                                            ?
                                                                <img src={track.image_path} alt="Mapa" />
                                                            :
                                                                null
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (track.first_race_date)
                                                    ?
                                                        (checkDeadline(track.first_practice_date))
                                                            ?
                                                                <Link
                                                                    to={"estrategia-carrera"}
                                                                    className="strategy"
                                                                    id={(checkDate(track.first_practice_date)) ? "active" : null }
                                                                    onClick={() => {
                                                                        handleOpen();
                                                                        localStorage.setItem("currentRace", JSON.stringify(track));
                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                    }}
                                                                >Estrategia</Link>
                                                            :
                                                                <Link
                                                                    to={"resultados-carrera"}
                                                                    className="race-results"
                                                                    onClick={
                                                                        () => {
                                                                            cargaListaUsuarios(concurso._id);
                                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                        }
                                                                    }
                                                                >Ver resultados</Link>
                                                    :
                                                        (checkDeadline(track.qualification_date))
                                                            ?
                                                                <Link
                                                                    to={"estrategia-carrera"}
                                                                    className="strategy"
                                                                    id={(checkDate(track.first_practice_date)) ? "active" : null }
                                                                    onClick={() => {
                                                                        handleOpen();
                                                                        localStorage.setItem("currentRace", JSON.stringify(track));
                                                                        localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                    }}
                                                                >Estrategia</Link>
                                                            :
                                                                <Link
                                                                    to={"resultados-carrera"}
                                                                    className="race-results"
                                                                    onClick={
                                                                        () => {
                                                                            cargaListaUsuarios(concurso._id);
                                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                                        }
                                                                    }
                                                                >Ver resultados</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            (open)
                                ?
                                    <Modal
                                        open={open}
                                        onClose={() => {
                                            handleClose()
                                        }}
                                    >
                                        <div className="pl-modal-races">
                                            <div
                                                className="close-modal"
                                                onClick={() => {
                                                    handleClose();
                                                }}
                                            >
                                                <img className="icon-normal" src={close} alt="Botón Cerrar Modal"/>
                                                <img className="icon-hover" src={closeHover} alt="Botón Cerrar Modal"/>
                                            </div>
                                            <Background/>
                                            {
                                                (currentRace.first_race_date)
                                                    ?
                                                        <div className="holder">
                                                            <div className="container-fluid">
                                                                <div className="header">
                                                                    <div className="title">Horarios</div>
                                                                    <div className="desc">{concurso.nombre} - {index} · {currentRace.name}</div>
                                                                </div>
                                                                <div className="content">
                                                                    <div className="flex-content">
                                                                        <div className="left">Práctica 1<span> {getDayOfWeek(currentRace.first_practice_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.first_practice_date)}</div>
                                                                    </div>
                                                                    <div className="advertising">Dead-line para selección de estrategia: {formatDateWithMinusOneMinute(currentRace.sprint_quali_date)}</div>
                                                                    <div className="flex-content">
                                                                        <div className="left">Sprint<span> {getDayOfWeek(currentRace.first_race_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.first_race_date)}</div>
                                                                    </div><br />
                                                                    <div className="flex-content">
                                                                        <div className="left">Sprint Quali<span> {getDayOfWeek(currentRace.sprint_quali_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.sprint_quali_date)}</div>
                                                                    </div><br/>
                                                                    <div className="flex-content">
                                                                        <div className="left">Qualifying<span> {getDayOfWeek(currentRace.qualification_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.qualification_date)}</div>
                                                                    </div><br/>
                                                                    <div className="flex-content">
                                                                        <div className="left"><b>Carrera<span> {getDayOfWeek(currentRace.race_date)}</span></b></div>
                                                                        <div className="right"><b>{extractTime(currentRace.race_date)}</b></div>
                                                                    </div>
                                                                    <div
                                                                        className="accept"
                                                                        onClick={() => {
                                                                            handleClose();
                                                                    }}
                                                                    >Aceptar</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                        <div className="holder">
                                                            <div className="container-fluid">
                                                                <div className="header">
                                                                    <div className="title">Horarios</div>
                                                                    <div className="desc">{concurso.nombre} - {index} · {currentRace.name}</div>
                                                                </div>
                                                                <div className="content">
                                                                    <div className="flex-content">
                                                                        <div className="left">Práctica 1<span> {getDayOfWeek(currentRace.first_practice_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.first_practice_date)}</div>
                                                                    </div><br/>
                                                                    <div className="flex-content">
                                                                        <div className="left">Práctica 2<span> {getDayOfWeek(currentRace.second_practice_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.second_practice_date)}</div>
                                                                    </div><br/>
                                                                    <div className="flex-content">
                                                                        <div className="left">Práctica 3<span> {getDayOfWeek(currentRace.third_practice_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.third_practice_date)}</div>
                                                                    </div>
                                                                    <div className="advertising">Dead-line para selección de estrategia: {formatDateWithMinusOneMinute(currentRace.qualification_date)}</div>
                                                                    <div className="flex-content">
                                                                        <div className="left">Qualifying<span> {getDayOfWeek(currentRace.qualification_date)}</span></div>
                                                                        <div className="right">{extractTime(currentRace.qualification_date)}</div>
                                                                    </div><br/>
                                                                    <div className="flex-content">
                                                                        <div className="left"><b>Carrera<span> {getDayOfWeek(currentRace.race_date)}</span></b></div>
                                                                        <div className="right"><b>{extractTime(currentRace.race_date)}</b></div>
                                                                    </div>
                                                                    <div
                                                                        className="accept"
                                                                        onClick={() => {
                                                                            handleClose();
                                                                    }}
                                                                    >Aceptar</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                            }
                                        </div>
                                    </Modal>
                                :
                                    null
                        }
                    </div>
                </div>
            </div>
            <Link to={"/dashboard"} className="back visible-xs">
                <div className="icon">
                    <img
                        src={backIcon}
                        alt="Regresar"
                    />
                </div>
                <div className="text">Regresar</div>
            </Link>
        </section>
    );
}

export default ListaCarreras;