import React, { useState, useEffect } from 'react';

// Import styles
import '../../design/styles/questions.scss';

import arrow from '../../design/imgs/expand-arrow.svg';

const Preguntas = ({
        selectedPosOne,
        setSelectedPosOne,
        selectedPosTwo,
        setSelectedPosTwo,
        selectedPosThree,
        setSelectedPosThree,
        selectedPosFour,
        setSelectedPosFour,
        selectedPosFive,
        setSelectedPosFive
    }) => {

    const [ posOneOpen, setPosOneOpen ] = useState(false)
    const [ posTwoOpen, setPosTwoOpen ] = useState(false)
    const [ posThreeOpen, setPosThreeOpen ] = useState(false)
    const [ posFourOpen, setPosFourOpen ] = useState(false)
    const [ posFiveOpen, setPosFiveOpen ] = useState(false)

    const togglingOne = () => setPosOneOpen(!posOneOpen);
    const onOptionClickedOne = value => () => {
        setPosOneOpen(false);
        setSelectedPosOne(value)
    };

    const togglingTwo = () => setPosTwoOpen(!posTwoOpen);
    const onOptionClickedTwo = value => () => {
        setPosTwoOpen(false);
        setSelectedPosTwo(value)
    };

    const togglingThree = () => setPosThreeOpen(!posThreeOpen);
    const onOptionClickedThree = value => () => {
        setPosThreeOpen(false);
        setSelectedPosThree(value)
    };

    const togglingFour = () => setPosFourOpen(!posFourOpen);
    const onOptionClickedFour = value => () => {
        setPosFourOpen(false);
        setSelectedPosFour(value)
    };

    const togglingFive = () => setPosFiveOpen(!posFiveOpen);
    const onOptionClickedFive = value => () => {
        setPosFiveOpen(false);
        setSelectedPosFive(value)
    };
    
    const fetchDriversData = async () => {
        const API_KEY = "JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP"; // Reemplaza con tu API Key
        const BASE_URL = "https://f1.sportmonks.com/api/v1.0";

        try {
            // 1️⃣ Obtener la lista de IDs de los conductores de la temporada
            const response = await fetch(`${BASE_URL}/drivers/season/10?api_token=${API_KEY}`);
            const data = await response.json();

            if (!data || !data.data) {
                throw new Error("No se pudo obtener la lista de conductores.");
            }

            const driverIds = data.data.map(driver => driver.driver_id); // Extrae los IDs de los conductores

            // 2️⃣ Hacer las peticiones para obtener la información completa de cada conductor
            const driverRequests = driverIds.map(id =>
                fetch(`${BASE_URL}/drivers/${id}?api_token=${API_KEY}`).then(res => res.json())
            );

            // 3️⃣ Esperar a que todas las peticiones terminen
            const driversInfo = await Promise.all(driverRequests);

            // 4️⃣ Extraer los datos y retornarlos
            return driversInfo.map(driver => driver.data);

        } catch (error) {
            console.error("Error al obtener los conductores:", error);
            return [];
        }
    };

    const [apiPilots, setPilots] = useState([])

    useEffect(() => {
        fetchDriversData();
        const loadPilots = async () => {
            const allPilots = await fetchDriversData();
            setPilots(allPilots)
        };
    
        loadPilots();
    }, [])

    return (
        <div>
            <div className='questions'>
                <div className='item'>
                    <div className='item-title'>Posición 1</div>
                    <div className='question'>¿Quién quedará primero esta carrera?</div>
                    <div className='custom-select'>
                        <div className='indication' onClick={togglingOne}>
                            <span className='text'>{selectedPosOne || "Elige tu respuesta"}</span>
                            <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                        </div>
                        {posOneOpen && (
                            <div className='options-wrapper'>
                                <ul className='list-opts'>
                                    {apiPilots.map(piloto => (
                                        <li onClick={onOptionClickedOne(piloto.name)} key={Math.random()}>
                                            {piloto.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className='item'>
                    <div className='item-title'>Posición 2</div>
                    <div className='question'>¿Quién quedará segundo esta carrera?</div>
                    <div className='custom-select'>
                        <div className='indication' onClick={togglingTwo}>
                            <span className='text'>{selectedPosTwo || "Elige tu respuesta"}</span>
                            <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                        </div>
                        {posTwoOpen && (
                            <div className='options-wrapper'>
                                <ul className='list-opts'>
                                    {apiPilots.map(piloto => (
                                        <li onClick={onOptionClickedTwo(piloto.name)} key={Math.random()}>
                                            {piloto.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className='item'>
                    <div className='item-title'>Posición 3</div>
                    <div className='question'>¿Quién quedará tercero esta carrera?</div>
                    <div className='custom-select'>
                        <div className='indication' onClick={togglingThree}>
                            <span className='text'>{selectedPosThree || "Elige tu respuesta"}</span>
                            <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                        </div>
                        {posThreeOpen && (
                            <div className='options-wrapper'>
                                <ul className='list-opts'>
                                    {apiPilots.map(piloto => (
                                        <li onClick={onOptionClickedThree(piloto.name)} key={Math.random()}>
                                            {piloto.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className='item'>
                    <div className='item-title'>Posición 4</div>
                    <div className='question'>¿Quién quedará cuarto esta carrera?</div>
                    <div className='custom-select'>
                        <div className='indication' onClick={togglingFour}>
                            <span className='text'>{selectedPosFour || "Elige tu respuesta"}</span>
                            <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                        </div>
                        {posFourOpen && (
                            <div className='options-wrapper'>
                                <ul className='list-opts'>
                                    {apiPilots.map(piloto => (
                                        <li onClick={onOptionClickedFour(piloto.name)} key={Math.random()}>
                                            {piloto.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className='item'>
                    <div className='item-title'>Posición 5</div>
                    <div className='question'>¿Quién quedará quinto esta carrera?</div>
                    <div className='custom-select'>
                        <div className='indication' onClick={togglingFive}>
                            <span className='text'>{selectedPosFive || "Elige tu respuesta"}</span>
                            <span className='arrow'><img src={arrow} alt='Arrow'/></span>
                        </div>
                        {posFiveOpen && (
                            <div className='options-wrapper'>
                                <ul className='list-opts'>
                                    {apiPilots.map(piloto => (
                                        <li onClick={onOptionClickedFive(piloto.name)} key={Math.random()}>
                                            {piloto.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Preguntas;