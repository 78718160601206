import React from 'react';

// Import Styles
import '../../design/styles/home.scss';

// Import logo
import bwrLogo from '../../design/imgs/brw-w.svg';

// Import partial
import FooterHome from '../partials/FooterHome';

import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className='outer-wrapper'>
            <section className="block home" id="home">
                <div className="background"></div>
                <div className="content">
                    <div className="icon">
                        <img src={bwrLogo} alt=""/>
                    </div>
                    <div className="title">Racing to the top!</div>
                    <div className="desc">Bienvenido a tu Quiniela de la Formula 1® 2025</div>
                    <div className="text">Juega, disfruta y vive a toda velocidad la emoción del automovilismo como nunca antes.<b>¿Estas listo para entrar a competir?</b></div>
                    <div className="buttons-wrapper">
                        <Link to={'/nueva-cuenta'} className='register'>
                            Registrarse
                        </Link>
                        <Link to={'/login'} className='login'>
                            Entrar
                        </Link>
                    </div>
                </div>
            </section>

            <FooterHome />
        </div>
    );
}

export default Home;