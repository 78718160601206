import React from 'react';
import rulesImage from '../../design/imgs/reglas.png';
import { Link } from 'react-router-dom';

// Import styles
import backIcon from '../../design/imgs/arrow-l-1.svg';
import '../../design/styles/rules.scss';

// Import partial
import MainNavigation from '../partials/MainNavigation';
import FooterHome from '../partials/FooterRegister';

const Rules = () => {
    return (

        <div className='outer-wrapper'>

            <MainNavigation/>

            <section className='block rules'>
                <div className='background'></div>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>
                                Reglas
                            </div>
                            <div className='desc'><b>REGLAMENTO GENERAL 2025</b></div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='text-wrapper'>
                                    <div className='text'>
                                        <h1><b>GENERALES</b></h1>
                                        <p>La <b>Quiniela BRW 2025</b> está diseñada para vivir la experiencia de la máxima categoría del automovilismo al límite. A continuación, te presentamos el Reglamento General para la temporada 2025: </p>
                                        <p><b>1.-  REGISTRO Y PERFIL:</b>  Es requisito indispensable generar tu perfil y elegir un “nickname” con el cual estarás participando en los diferentes bloques. Podrás crear los perfiles y “nicknames” que desees, recuerda que cada perfil requerirá de créditos para jugar y no podrán ser intercambiables</p>
                                        <p><b>2.- FORMA DE PAGO:</b> Para poder participar en un bloque, es necesario que cuentes con los créditos necesarios, por lo que deberás solicitar dichos créditos a través de la plataforma. El participante podrá abonar a su perfil tantos créditos desee, antes, durante y después del inicio de la temporada.</p>
                                        <p>Nota: La plataforma no permitirá ingresar a un bloque si no cuenta con los créditos suficientes.</p>
                                        <p>En caso de que algún participante decida retirarse del juego en el momento que sea o deje de participar activamente en el ingreso de sus estrategias, éste no será acreedor a la devolución de sus créditos.</p>
                                        <p>En caso de fallecimiento de un participante, no habrá devolución de créditos. Si éste fuera acreedor a algún premio entonces su ganancia se repartirá en créditos y en partes iguales entre todos los participantes de ese bloque.</p>
                                        <p><b>3.- BLOQUES:</b> Existirán diferentes bloques en los cuales podrán participar utilizando sus créditos disponibles. Cada bloque tendrá un costo de juego específico (créditos) y habrá un máximo de 20 participantes por bloque.</p>
                                        <p>No se podrá acumular, compartir o traspasar puntos y/o créditos de otros bloques.</p>
                                        <p><b>4.- ASPECTOS TÉCNICOS Y PUNTAJES:</b> A continuación, se describen las 3 modalidades de juego (“SUERTE”, “ESTRATEGIA” y “PREGUNTAS”) que integrarán cada bloque:</p>
                                        <div>
                                            <ul>
                                                <li key='uno'>
                                                    <p><b>a) Modalidad “SUERTE”</b> – Se hará un sorteo para cada Gran Premio, el cual asignará al azar un PILOTO y un EQUIPO CONSTRUCTOR para cada uno de los participantes. Existirá la posibilidad de que toque el mismo PILOTO y/o EQUIPO CONSTRUCTOR en dos o más carreras, ya que la suerte es el factor principal en esta modalidad.</p><br/>
                                                    <p>Al haber 20 pilotos (2 por equipo constructor) y 10 equipos constructores (20 automóviles), la asignación por carrera a cada uno de los 20 participantes es automática. Por ende, no podrá haber un participante sin PILOTO y/o sin EQUIPO CONSTRUCTOR asignado en esta modalidad.</p><br/>
                                                    <p>El video con el sorteo de cada carrera podrá ser consultado en nuestro canal de YouTube (Bet Race Win), a más tardar 24 horas previas a la Práctica 1 de cada Gran Premio. Es responsabilidad de cada participante estar atento al contenido del canal, por lo que se recomienda ampliamente suscribirse y activar las notificaciones. De igual manera, se cargarán en la plataforma los resultados del sorteo.</p><br/>
                                                    <p>Los puntos que generen los PILOTOS y los EQUIPOS CONSTRUCTORES en cada carrera conforme a los datos publicados en la página web oficial de la Formula 1, serán los que se le atribuyan al participante.</p>
                                                </li>
                                                <li key='dos'>
                                                    <p><b>b) Modalidad “ESTRATEGIA”</b>– Cada participante deberá elegir a un PILOTO y a un EQUIPO CONSTRUCTOR por carrera, sin la posibilidad de repetir las elecciones previas. La estrategia de cada uno es el factor principal en esta modalidad.</p><br/>
                                                    <p>No obstante, al haber 20 pilotos (2 por equipo constructor), 10 equipos constructores (20 automóviles), cada participante deberá elegir forzosamente a los 20 PILOTOS y a los 20 automóviles (EQUIPOS CONSTRUCTORES) al menos una vez en toda la temporada.</p><br/>
                                                    <p>En caso de que el campeonato tenga 21 carreras o más, cada participante podrá elegir de nuevo PILOTOS y EQUIPOS CONSTRUCTORES, los cuales se denominarán “COMODINES”, sin importar que ya los hayan elegido anteriormente, y podrán ser utilizados en cualquier momento de la temporada.</p><br/>
                                                    <p>En caso de que existan menos carreras en la temporada que el número de pilotos entonces quedará anulada la opción de utilizar los “COMODINES”. </p><br/>
                                                    <p>Las dos opciones elegidas (PILOTO y EQUIPO CONSTRUCTOR) de cada participante deberán quedar guardadas en la plataforma, a más tardar en el “Deadline” que se establece para cada Gran Premio. Recuerda, algunas carreras tendrán la modalidad de “Sprint Race”, por lo que los “Deadlines” cambiarán dependiendo de los horarios. Cualquier respuesta enviada fuera de los tiempos establecidos será automáticamente acreedora a 0 (cero) puntos.</p>
                                                </li>
                                                <li key='tres'>
                                                    <p><b>c) Modalidad “PREGUNTAS”</b> – Para cada carrera de la temporada, cada participante deberá contestar las 3 (tres) preguntas siguientes:</p><br/>
                                                    <div>
                                                        <ul>
                                                            <li key='tresuno'><p><b>1. ¿En qué posición quedará Sergio “Checo” Pérez en la carrera?</b> = 25 pts.</p></li>
                                                            <li key='tresdos'><p><b>2. ¿Quién se llevará el DHL “Fastest Lap Award”?</b> = 25 pts.</p></li>
                                                            <li key='trestres'><p><b>3. ¿Quién será el primer DNF “Did not Finish” de la carrera?</b> = 25 pts.</p></li>
                                                        </ul>
                                                    </div><br/>
                                                    <p>Para la pregunta #1, las respuestas pueden ser de lugar 1 al 20 o bien, DNF (Did Not Finish) o DNS (Did Not Start). En caso de que Sergio “Checo” Pérez sea reemplazado por otro piloto por la razón que sea, entonces se tomará en cuenta la posición de llegada del reemplazo.</p><br/>
                                                    <p>De igual manera, se tomarán en cuenta aquellos pilotos que reemplacen a los oficiales para el registro de las preguntas #2 y #3 (DHL “Fastest Lap Award” y DNF “Did Not Finish”).</p><br/>
                                                    <p>El acertar a cualquiera de estas preguntas se sumarán 25 (veinticinco) puntos por acierto al total de esa carrera, y 0 (cero) puntos en caso de no acertar. Las respuestas a las preguntas serán tomadas como buena o mala, por lo que no podrá otorgarse otro puntaje y el puntaje máximo posible por carrera será de 75 (setenta y cinco) puntos en esta modalidad.</p><br/>
                                                    <p>Las opciones elegidas a estas preguntas de cada participante deberán quedar guardadas en la plataforma, a más tardar en el “Deadline” que se establece para cada Gran Premio. Recuerda, algunas carreras tendrán la modalidad de “Sprint Race”, por lo que los “Deadlines” cambiarán dependiendo de los horarios. Cualquier respuesta enviada fuera de los tiempos establecidos será automáticamente acreedora a 0 (cero) puntos.</p><br/>
                                                </li>
                                            </ul>
                                        </div>
                                        <p><b>5.- SUMATORIA DE PUNTOS:</b> La suma de los puntos generados para cada bloque en las 3 (tres) modalidades, “SUERTE”, “ESTRATEGIA” y “PREGUNTAS”, definirán su posición en la tabla para cada bloque. El objetivo es sumar la mayor cantidad de puntos posibles al final de la temporada. Es responsabilidad de cada participante estar al pendiente de las fechas, de sus elecciones y del cumplimiento en los “Deadlines”. Se recomienda llevar sus propios registros de puntuación.</p>
                                        <p>Los puntos que generen los PILOTOS y los EQUIPOS CONSTRUCTORES en cada carrera conforme a los datos publicados en la página web oficial de la Formula 1, serán los que se le atribuyan al participante. </p>
                                        <p>Cualquier modificación con respecto al campeonato y sus carreras, a pilotos y a equipos constructores, así como a los puntos en caso de descalificación, penalizaciones o similares durante el transcurso del año 2025, serán aplicadas de la misma forma en los registros de cada bloque.</p>
                                        <p><b>5.- SORTEOS:</b> Los sorteos se presentarán a través del Canal Oficial de YouTube (Bet Race Win) y conforme al número de posición de cada participante en la Tabla General del bloque será el PILOTO y el EQUIPO CONSTRUCTOR asignado. No habrá cambios ni repetición del sorteo.</p>
                                        <p><b>6.- REEMPLAZO DE PILOTOS:</b> En caso de que algún piloto se vea imposibilitado para correr en una carrera y éste sea reemplazado por otro piloto, ya sea el de reserva del equipo o un tercero, el piloto que reemplace sumará los puntos tanto para el piloto que reemplazó como para el equipo que represente en esa carrera. Por lo que elegir al piloto o elegir al reemplazo significará exactamente lo mismo.</p>
                                        <p>En caso de que un equipo constructor decida no correr y no inicien sus autos/pilotos, entonces no se contabilizará como DNS (Did Not Start).</p>
                                        <p><b>7.- CARRERAS “SPRINT”:</b> Existirán 3 (tres) Grandes Premios con el formato “SPRINT RACE”. Tanto las sumatoria de puntos, como las estrategias no sufrirán cambios y serán de acuerdo a lo que se establece en el numeral 5 de este Reglamento. Los horarios y “Deadlines” para guardar los registros podrán cambiar, por lo que se recomienda estar atento a estos datos durante la temporada.</p>
                                        <p><b>8.- DESEMPATE:</b> En caso de uno o más empates, estos se definirán conforme a cada carrera y al finalizar la temporada considerando todos los puntos que hayan sumado de la siguiente manera:</p>
                                        <div>
                                            <li key='primer'><p><b>a.</b> Aquel que haya sumado la mayor cantidad de puntos por PILOTO en la modalidad de “ESTRATEGIA”.</p></li>
                                            <li key='segundo'><p><b>b.</b> Aquel que haya sumado la mayor cantidad de puntos por EQUIPO CONSTRUCTOR en la modalidad de “ESTRATEGIA”.</p></li>
                                            <li key='tercer'><p><b>c.</b> Aquel que haya sumado la mayor cantidad de puntos en la modalidad de “PREGUNTAS”.</p></li>
                                            <li key='cuarto'><p><b>d.</b> Aquel que haya sumado la mayor cantidad de puntos por PILOTO en la modalidad de “SUERTE”.</p></li>
                                            <li key='quinto'><p><b>e.</b> Aquel que haya sumado la mayor cantidad de puntos por EQUIPO CONSTRUCTOR en la modalidad de “SUERTE”.</p></li>
                                            <li key='sexto'><p><b>f.</b> Por volado.</p></li>
                                        </div>
                                        <p><b>9.- PREMIOS:</b> Se repartirán premios a 4 (cuatro) participantes, los primeros 3 mejores participantes de cada bloque, es decir, al 1ro, 2do y 3er Lugar y al 10mo Lugar, contabilizado de mayor a menor en la sumatoria total de puntos.</p>
                                        <p>Los porcentajes de premiación se repartirán entre los 4 (cuatro) ganadores, cada uno con un porcentaje sobre el total de la bolsa a repartir, conforme a la siguiente tabla: </p>
                                        <p>1er Lugar - 55%</p>
                                        <p>2do Lugar - 25%</p>
                                        <p>3er Lugar - 15%</p>
                                        <p>10mo Lugar - 5%</p>
                                        <p>Síguenos en Instagram y suscríbete a nuestro canal de YouTube para estar al tanto de las actualizaciones y seguimiento de la máxima categoría del automovilismo.</p>
                                        <p><b>Bienvenidos a esta, tu Quiniela BRW 2025 y toda la suerte!</b></p>
                                    </div>
                                </div>
                                <div className='image-wrapper hidden-xs'>
                                    <div className='image'>
                                        <img
                                            src={rulesImage}
                                            alt="Imagen"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={'/dashboard'} className='back'>
                    <div className='icon'>
                        <img
                            src={backIcon}
                            alt="Regresar"
                        />
                    </div>
                    <div className='text'>Regresar</div>
                </Link>
            </section>

            <FooterHome/>

        </div>

    );
}

export default Rules;