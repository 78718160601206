import React, { Fragment, useState, useEffect } from 'react';
import MainNavigation from '../partials/MainNavigation';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import sideBar from '../../design/imgs/barra-lateral.jpg';

const SideBarBackground = styled.div`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;

const Races = () => {

    const convertToCDMXTime = (utcTimestamp) => {
        return new Intl.DateTimeFormat("es-MX", {
            timeZone: "America/Mexico_City",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
        }).format(new Date(utcTimestamp * 1000)); // Convertimos a milisegundos
    };

    // Función para convertir "DD/MM/YYYY, HH:mm:ss" → "YYYY-MM-DDTHH:mm:ss"
    const parseDate = (dateStr) => {
        const [datePart, timePart] = dateStr.split(", ");
        const [day, month, year] = datePart.split("/");

        return new Date(`${year}-${month}-${day}T${timePart}`);
    };

    const fetchStages = async () => {
        try {
            const response = await fetch(
                `https://f1.sportmonks.com/api/v1.0/stages/season/10?api_token=JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP`
            );
            const data = await response.json();

            // Filtrar las etapas
            const relevantStages = data.data.filter(
                (stage) => stage.name === "Race"
                            || stage.name === "First Qualification"
                            || stage.name === "Sprint Race"
                            || stage.name === "First Practice"
                            || stage.name === "Second Practice"
                            || stage.name === "Third Practice"
                            || stage.name === "Sprint Shootout 1"
            );

            // Retornar un array con { track_id, start_date }
            return relevantStages.map((stage) => ({
                track_id: stage.track_id,
                name: stage.name,
                start_date: convertToCDMXTime(stage.time.starting_at.timestamp),
                total_laps: stage.total_laps,
                distance: stage.distance,
                city: stage.city,
                stage_id: stage.id
            }));

        } catch (error) {
            console.error("Error fetching stages:", error);
            return [];
        }
    };

    const fetchTrackDetails = async (trackIds) => {
        try {
            const response = await fetch(
                `https://f1.sportmonks.com/api/v1.0/tracks?api_token=JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP`
            );
            const data = await response.json();

            // Filtrar solo las pistas que están en trackIds
            return data.data.filter((track) => trackIds.includes(track.id));
        } catch (error) {
            console.error("Error fetching tracks:", error);
            return [];
        }
    };
    
        const getTracks = async () => {
            // 1. Obtener las etapas (Race, First Qualification y First Race)
            const stages = await fetchStages();
    
            // 2. Extraer los track_id únicos
            const trackIds = [...new Set(stages.map((stage) => stage.track_id))];
    
            // 3. Obtener los detalles de las pistas
            const trackDetails = await fetchTrackDetails(trackIds);
    
            // 4. Combinar las pistas con sus eventos relevantes
            const mergedData = trackDetails.map((track) => {
                const race = stages.find((s) => s.track_id === track.id && s.name === "Race");
                const qualification = stages.find((s) => s.track_id === track.id && s.name === "First Qualification");
                const firstRace = stages.find((s) =>s.track_id === track.id && s.name === "Sprint Race");
                const firstPractice = stages.find((s) =>s.track_id === track.id && s.name === "First Practice");
                const secondPractice = stages.find((s) =>s.track_id === track.id && s.name === "Second Practice");
                const thirdPractice = stages.find((s) =>s.track_id === track.id && s.name === "Third Practice");
                const sprintQuali = stages.find((s) =>s.track_id === track.id && s.name === "Sprint Shootout 1");
    
                return {
                    ...track,
                    race_date: race ? race.start_date : null,
                    total_laps: race ? race.total_laps : null,
                    city: race ? race.city : null,
                    distance: race ? race.distance : null,
                    qualification_date: qualification ? qualification.start_date : null,
                    first_race_date: firstRace ? firstRace.start_date : null,
                    first_practice_date: firstPractice ? firstPractice.start_date : null,
                    second_practice_date: secondPractice ? secondPractice.start_date : null,
                    third_practice_date: thirdPractice ? thirdPractice.start_date : null,
                    sprint_quali_date: sprintQuali ? sprintQuali.start_date : null,
                    race_id: race ? race.stage_id : null,
                    sprint_id: firstRace ? firstRace.stage_id : null
                };
            });
    
            // 5. Ordenar por fecha de inicio
            const sortedTracks = mergedData
                .filter((track) => track.race_date) // Filtrar los que tienen fecha
                .sort((a, b) => parseDate(a.race_date) - parseDate(b.race_date));
    
            return sortedTracks;
        };
    
        const [tracks, setTracks] = useState([]);
    
        useEffect(() => {
            const loadTracks = async () => {
                const sortedTracks = await getTracks();
                setTracks(sortedTracks);
            };
        
            loadTracks();
        }, []);

    return (
        <div className='outer-wrapper'>
            <MainNavigation/>
            <div className='races-wrapper'>
                <section className='block sideBar-races'>
                    <SideBarBackground
                        style={{
                            backgroundImage: `url(${sideBar})`
                        }}
                    />
                    <div className='holder'>
                        <div className='content'>
                        </div>
                    </div>
                </section>
                <section className="block races-list">

                    <div className="holder">
                        <div className="container-fluid">
                            <div className="content">
                                {
                                    tracks.map((track, index) => (
                                        <div className='race' key={index}>
                                            <div className='right-side'>
                                                <div className="country">{track.city} - {track.name}</div>
                                                <Fragment>
                                                    <Link
                                                        to={"/estrategia"}
                                                        className="gplk-link"
                                                        onClick={() => {
                                                            localStorage.setItem("currentRace", JSON.stringify(track));
                                                            localStorage.setItem("index", ((index < 9) ? "0" + (index + 1) : (index + 1)));
                                                        }}
                                                    >
                                                        <div className="text">Cargar Resultados</div>
                                                    </Link>
                                                </Fragment>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <Link to={"/dashboard"} className="back">
                        <div className="text">Regresar</div>
                    </Link>
                </section>
            </div>
        </div>
    )
}

export default Races;