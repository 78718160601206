import React, {useState, useEffect, useContext} from 'react';
import AuthContext from '../../context/autenticacion/authContext';

// Import styles
import '../../design/styles/constructors-list.scss';

const ListaConstructores = ({constructorSeleccionado, SeleccionarConstructor, participantesInscritos, selectedOptionConstructor, setSelectedOptionConstructor}) => {

    const [teams, setTeams] = useState([]);
    const API_KEY = "JIkOohm71t8mO7q1L1M3PyGnaihRYWfSo9hpCY88ubdDRN2IGLoPoWuLVKPP"; // Reemplázalo con tu API Key
    const BASE_URL = "https://f1.sportmonks.com/api/v1.0";

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await fetch(`${BASE_URL}/teams/season/10?api_token=${API_KEY}`);
                const data = await response.json();
    
                if (data && data.data) {
                    // Duplicamos los datos
                    const duplicatedTeams = [...data.data, ...data.data];
    
                    // Mapa para contar repeticiones por nombre
                    const teamCount = {};
    
                    // Asignamos un ID único a cada repetición
                    const updatedTeams = duplicatedTeams.map((team) => {
                        const count = teamCount[team.name] ? teamCount[team.name] + 1 : 1;
                        teamCount[team.name] = count;
                        
                        return {
                            ...team,
                            team_id: `${team.team_id}_${count}`, // Nuevo ID con sufijo incremental
                        };
                    });
    
                    setTeams(updatedTeams);
                } else {
                    console.error("No se encontraron equipos.");
                }
            } catch (error) {
                console.error("Error al obtener los equipos:", error);
            }
        };
    
        fetchTeams();
    }, []); // Se ejecuta solo una vez al montar el componente

    let comodinConstructor = 0;

    const onConstructorSelection = value => () => {
        SeleccionarConstructor(value);
        setSelectedOptionConstructor({});
        localStorage.setItem('comodinConstructor', (comodinConstructor + 1));
    };

    // Extraer la información de autenticación
    const authContext = useContext(AuthContext);
    const {usuario} = authContext;

    const [isOpenConstructor, setIsOpenConstructor] = useState(false);

    const togglingConstructor = () => setIsOpenConstructor(!isOpenConstructor);

    const onOptionClickedConstructor = value => () => {
        setSelectedOptionConstructor(value);
        setIsOpenConstructor(false);
        SeleccionarConstructor(value);
        localStorage.setItem('comodinConstructor', (comodinConstructor));
    };

    const currentParticipante = localStorage.getItem('currentParticipant');

    return (
        <div className='lista-constructores'>

            {teams.map((constructor, index) => (
                    <div
                        className='item-container'
                        id={constructor.team_id}
                        key={index}
                    >
                        {participantesInscritos.map(participante => (
                            (currentParticipante === participante.id_participante)
                                ?
                                    (
                                        (constructor.team_id) === participante.id_pick_constructor_01 ||
                                        (constructor.team_id) === participante.id_pick_constructor_02 ||
                                        (constructor.team_id) === participante.id_pick_constructor_03 ||
                                        (constructor.team_id) === participante.id_pick_constructor_04 ||
                                        (constructor.team_id) === participante.id_pick_constructor_05 ||
                                        (constructor.team_id) === participante.id_pick_constructor_06 ||
                                        (constructor.team_id) === participante.id_pick_constructor_07 ||
                                        (constructor.team_id) === participante.id_pick_constructor_08 ||
                                        (constructor.team_id) === participante.id_pick_constructor_09 ||
                                        (constructor.team_id) === participante.id_pick_constructor_10 ||
                                        (constructor.team_id) === participante.id_pick_constructor_11 ||
                                        (constructor.team_id) === participante.id_pick_constructor_12 ||
                                        (constructor.team_id) === participante.id_pick_constructor_13 ||
                                        (constructor.team_id) === participante.id_pick_constructor_14 ||
                                        (constructor.team_id) === participante.id_pick_constructor_15 ||
                                        (constructor.team_id) === participante.id_pick_constructor_16 ||
                                        (constructor.team_id) === participante.id_pick_constructor_17 ||
                                        (constructor.team_id) === participante.id_pick_constructor_18 ||
                                        (constructor.team_id) === participante.id_pick_constructor_19 ||
                                        (constructor.team_id) === participante.id_pick_constructor_20 ||
                                        (constructor.team_id) === participante.id_pick_constructor_21 ||
                                        (constructor.team_id) === participante.id_pick_constructor_22
                                    )
                                            ?
                                                <div className='already-selected'></div>
                                            :
                                                null
                                :
                                    null
                        ))}
                        <div
                            onClick={onConstructorSelection({
                                constructorId: constructor.team_id,
                                constructorNombre: constructor.name
                            })}
                            className={(constructorSeleccionado.constructorId === constructor.team_id && selectedOptionConstructor.constructorNombre == null) ? 'item selected' : 'item'}
                        >
                            <div className='logo'>
                                <img src={constructor.image_path} alt='Constructor'/>
                            </div>
                            <div className='constructor-name'>{constructor.name}</div>
                        </div>
                    </div>
            ))}

            {/*}<div className='questions comodins' key={Math.random()}>
                <div className='comodin'>
                    {participantesInscritos.map(participante => (
                        (participante.id_participante === usuario._id) ?
                            (participante.comodines_disponibles_constructores > 0) ?
                                <div>
                                    <div className='hidden'>{comodinConstructor = participante.comodines_disponibles_constructores}</div>
                                    {
                                        (participante.comodines_disponibles_constructores === 1)
                                            ?
                                                <div className='title'>Selecciona tu comodín para constructores.</div>
                                            :
                                                <div className='title'>Tienes {participante.comodines_disponibles_constructores} comodines disponibles</div>
                                    }
                                    <div className='custom-select'>
                                        <div className='indication' onClick={togglingConstructor}>
                                            <span className='text'>{selectedOptionConstructor.constructorNombre || "Elige tu respuesta"}</span>
                                        </div>
                                        {isOpenConstructor && (
                                            <div className='options-wrapper'>
                                                <ul className='list-opts'>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'McLaren*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        McLaren
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Ferrari*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Ferrari
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Red Bull*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Red Bull
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Mercedes*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Mercedes
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Aston Martin*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Aston Martin
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Alpine*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Alpine
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Haas*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Haas
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Racing Bulls*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Racing Bulls
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Williams*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Williams
                                                    </li>
                                                    <li
                                                        onClick={
                                                            onOptionClickedConstructor({
                                                                constructorId: 0,
                                                                constructorNombre: 'Sauber/Audi*'
                                                            })
                                                        }
                                                        key={Math.random()}
                                                    >
                                                        Sauber/Audi
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            :
                            <div className='title'>Ya no tienes comodines disponibles</div>
                        : null
                    ))}
                    </div>
                </div>{*/}
        </div>
    );
}

export default ListaConstructores;